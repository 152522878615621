export const url = 'https://scananalytics.kz/system/'
export const key = '917a34072663f9c8beea3b45e8f129c5'
export const date_format = 'YYYY-MM-DD'
export const charts_colors = [
	'#4779d0',
	'#cf6662',
	'#8fc144',
	'#729EA1',
	'#B5BD89',
	'#DFBE99',
	'#EC9192',
	'#DB5375',
	'#065143',
	'#129490',
	'#70B77E',
	'#E0A890',
	'#CE1483',
	'#CEBEBE',
	'#ECE2D0',
	'#D5B9B2',
	'#A26769',
	'#6D2E46',
	'#BFD7EA',
	'#91AEC1',
	'#508CA4',
	'#0A8754',
	'#004F2D',
	'#E3D888',
	// '#5b277d',
	// '#00a5ff',
	// '#ff0000',
	// '#ff6c00',
	// '#ffda00',
	// '#009f03',
	// '#004601',
	// '#5b2a00',
	// '#D512E5',
	// '#6026A1',
	// '#CF2E05',
	// '#DDCE1E',
	// '#77872A',
	// '#9FC5ED',
]

export const keys_translaltion_dictionary = {
	rounded_timestamp: 'Час',
	count_mentions: 'Упоминаний',
	total: 'Упоминаний',
	sum_er: 'Вовлеченность',
	sum_audience: 'Аудитория',
	neitral: 'Нейтральные',
	positive: 'Позитивные',
	negative: 'Негативные',
	count_authors: 'Авторов',
	percent: 'Процент',
	женский: 'Женский',
	мужской: 'Мужской',
	date: 'Дата',
	id: 'Id сообщения',
	text: 'Текст',
	hub: 'Источник',
	url: 'Url',
	hubtype: 'Тип источника',
	type: 'Типа сообщения',
	author_fullname: 'Автор',
	author_url: 'Url автора',
	author_type: 'Тип автора',
	author_sex: 'Пол',
	author_age: 'Возраст',
	audienceCount: 'Аудитория',
	audience: 'Аудитория',
	commentsCount: 'Коммантариев',
	repostsCount: 'Рапостов',
	likesCount: 'Лайков',
	er: 'Вовлеченность',
	viewsCount: 'Просмотров',
	review_rating: 'Оценка',
	duplicateCount: 'Дублей',
	toneMark: 'Тональность',
	role: 'Роль объекта',
	aggression: 'Агрессия',
	country: 'Страна',
	region: 'Регион',
	city: 'Город',
	geo_address: 'Адрес',
	language: 'Язык',
	WOM: 'WOM',
	processed: 'Обработан',
	total_percent: '% сообщений',
	geo_title: 'Название',
}
