export const ru_kz_dict = {
	vhod: {
		ru: 'ВХОД',
		kz: 'Кіру',
	},
	login_oshibka: {
		ru: 'Неверное имя пользователя или пароль',
		kz: 'Қолданушы аты немесе құпия сөз дұрыс емес',
	},
	vvedite_login: {
		ru: 'Введите логин!',
		kz: 'Логинді енгізіңіз!',
	},
	vvedite_parol: {
		ru: 'Введите пароль!',
		kz: 'Құпия сөзді еңгізіңіз!',
	},
	zapomnitb: {
		ru: 'Запомнить меня',
		kz: 'Есте сақтау',
	},
	dannie: {
		ru: 'Данные собираются с ',
		kz: 'Деректер  бастап жиналады',
	},
	tema: {
		ru: 'Тема',
		kz: 'Тақырып',
	},
	temi: {
		ru: 'Темы',
		kz: 'Тақырыптар',
	},
	all_groups: {
		ru: 'Все группы',
		kz: 'Барлық топтар',
	},
	commercial: {
		ru: 'Коммерческие',
		kz: 'Коммерциялық',
	},
	smi: {
		ru: 'СМИ',
		kz: 'БАҚ',
	},
	smi_tooltip: {
		ru: 'Онлайн-СМИ, пресса, ТВ, радио',
		kz: 'Онлайн, БАҚ, баспасөз, телевидение, радио',
	},
	sm: {
		ru: 'СМ',
		kz: 'ӘМ',
	},
	sm_tooltip: {
		ru: 'Социальные медиа и онлайн-СМИ',
		kz: 'Әлеуметтік желілер және онлайн БАҚ',
	},
	title: {
		ru: 'Название',
		kz: 'Атауы',
	},
	actions: {
		ru: 'Действия',
		kz: 'Әрекеттер',
	},
	otchet: {
		ru: 'Сводный отчет',
		kz: 'Жиынтық есеп',
	},
	settings: {
		ru: 'Настройки',
		kz: 'Параметлер',
	},
	exit: {
		ru: 'Выход',
		kz: 'Шығу',
	},
	help: {
		ru: 'Помощь',
		kz: 'Көмек',
	},
	sources: {
		ru: 'Источники',
		kz: 'Деректер',
	},
	authors: {
		ru: 'Авторы',
		kz: 'Авторлар',
	},
	community: {
		ru: 'Сообщества',
		kz: 'Қауымдастықтар',
	},
	geography: {
		ru: 'География',
		kz: 'География',
	},
	tegs: {
		ru: 'Теги',
		kz: 'Тегтер',
	},
	popular_words: {
		ru: 'Популярные слова',
		kz: 'Танымал сөздер',
	},
	links: {
		ru: 'Ссылки',
		kz: 'Сілтемелер',
	},
	persons: {
		ru: 'Персоны',
		kz: 'Тұлғалар',
	},
	ur_faces: {
		ru: 'Юрлица',
		kz: 'Занды тұлға',
	},
	places: {
		ru: 'Места',
		kz: 'Жерлер',
	},
	basket: {
		ru: 'Корзина',
		kz: 'Себет',
	},
	count_upominaniy: {
		ru: 'Количество упоминаний',
		kz: 'Ескертулер саны',
	},
	pozitive: {
		ru: 'Позитив',
		kz: 'Позитив',
	},
	negative: {
		ru: 'Негатив',
		kz: 'Негатив',
	},
	neutral: {
		ru: 'Нейтрально',
		kz: 'Бейтарап',
	},
	edit: {
		ru: 'редактировать разметку',
		kz: 'белгілеуді өңдеу',
	},
	edit_tooltip: {
		ru: 'Редактировать',
		kz: 'Өңдеу',
	},
	stop_tooltip: {
		ru: 'Остановить',
		kz: 'Тоқтау',
	},
	delete_tooltip: {
		ru: 'Удалить',
		kz: 'Жою',
	},
	add_theme: {
		ru: 'Добавить новую тему',
		kz: 'Жаңа тақырып қосу',
	},
	add_tags: {
		ru: 'добавить теги',
		kz: 'тегдерді қосу',
	},
	comment: {
		ru: 'Коммент',
		kz: 'Пікір',
	},
	author_auditory: {
		ru: 'Аудитория автора',
		kz: 'Автордың аудиториясы',
	},
	in: {
		ru: 'в',
		kz: 'ішінде',
	},
	message_auditory: {
		ru: 'Аудитория сообщения',
		kz: 'Хабар аудиториясы',
	},
	obrabotannoe_tooltip: {
		ru: 'Пометить как обработанное',
		kz: 'Өңделген деп белгілеу',
	},
	favorite_msg_tooltip: {
		ru: 'Добавить в избранное',
		kz: 'Таңдаулыларға қосу',
	},
	neutral_tooltip: {
		ru: 'Нейтрально',
		kz: 'Бейтарап',
	},
	poruchenie_tooltip: {
		ru: 'Создать поручение',
		kz: 'Тапсырма жасау',
	},
	share_tooltip: {
		ru: 'Поделиться',
		kz: 'Бөлісу',
	},
	show_full: {
		ru: 'Показать полный текст >',
		kz: 'Толық текстті көрсету >',
	},
	tech_sup: {
		ru: 'Написать в техподдержку',
		kz: 'Техникалық қолдау қызметіне жазыңыз',
	},

	comment_obrazovanie_10_2022: [
		{
			ru: 'С 2020 года функционируют отечественные цифровые полиязычные образовательные платформы Bilimland.kz, Күнделік, Білім ал, Дарын онлайн, которые имеют контент на английском языке.',
			kz: '2020 жылдан бастап ағылшын тілінде мазмұны бар Bilimland.kz, Kundelik, Bilim al, Daryn online отандық цифрлық көптілді білім беру платформалары жұмыс істейді.',
		},
		{
			ru: 'В рамках государственной программы развития образования и науки Республики Казахстан на 2020-2025 гг. предусмотрено повышение стоимости государственного заказа (гранта) с 1 сентября 2022 года для приема в вузы на программу высшего образования на 2022-2023 учебный год',
			kz: 'Қазақстан Республикасының білім мен ғылымды дамытудың 2020-2025 жылдарға арналған мемлекеттік бағдарламасы аясында. 2022 жылдың 1 қыркүйегінен бастап 2022-2023 оқу жылына жоғары білім беру бағдарламасы бойынша жоғары оқу орындарына түсуге арналған мемлекеттік тапсырыс (грант) құнын арттыру жоспарлануда.',
		},
	],
	comment_obrazovanie_11_2022: [
		{
			ru: 'В соответствии с Правилами разработки, согласования и утверждения образовательных программ курсов повышения квалификации педагогов при Министерстве образования и науки РК создан экспертный совет для проведения экспертизы образовательных программ курсов повышения квалификации педагогов на 2020-2023 гг., реализуемых за счет средств республиканского и местных бюджетов.',
			kz: 'Педагог қызметкерлердің біліктілігін арттыру курстарының білім беру бағдарламаларын әзірлеу, бекіту және бекіту қағидаларына сәйкес Қазақстан Республикасы Білім және ғылым министрлігі жанынан біліктілікті арттырудың білім беру бағдарламаларына сараптама жүргізу үшін сараптамалық кеңес құрылды. Республикалық және жергілікті бюджет қаражаты есебінен жүзеге асырылатын 2020-2023 жылдарға арналған мұғалімдерге арналған курстар.',
		},
		{
			ru: 'На системной основе будут проводиться республиканские конкурсы профессионального мастерства среди педагогов, в т.ч. конкурса «Лучший педагог», обобщение лучших практик педагогов на региональном, республиканском уровнях. Все это позволит сформировать команду современных менеджеров, а также кадровый резерв руководителей ТиПО через проект «Корпус лидеров». ',
			kz: 'Жүйелі негізде мұғалімдер арасында республикалық кәсіби шеберлік байқаулары өткізілетін болады. «Үздік педагог» байқауы, облыстық, республикалық деңгейде мұғалімдердің озық іс-тәжірибесін жалпылау. Осының барлығы «Көшбасшылар корпусы» жобасы арқылы заманауи менеджерлер командасын, сондай-ақ ТжКБ көшбасшыларының кадрлық резервін қалыптастыруға мүмкіндік береді.',
		},
	],
	comment_obrazovanie_12_2022: [
		{
			ru: 'На сегодня 100% колледжей имеют доступ к более чем 30 платформам (Platonus, Edu page, Canvas, CollegeSmartNation, Google Classroom, ЕИСО УО, Moodle, BilimAl, Edus, Sova, и другие). В настоящее время разрабатываются методические рекомендации по установлению единых требований к информационным системам, позволяющие установить единый подход по интеграции с информационными системами.',
			kz: 'Бүгінгі таңда колледждердің 100%-ы 30-дан астам платформаларға қол жеткізе алады (Platonus, Edu page, Canvas, CollegeSmartNation, Google Classroom, ENISE UO, Moodle, BilimAl, Edus, Sova және т.б.). Қазіргі уақытта ақпараттық жүйелермен интеграцияның бірыңғай тәсілін орнатуға мүмкіндік беретін ақпараттық жүйелерге бірыңғай талаптарды белгілеу бойынша әдістемелік ұсыныстар әзірленуде.',
		},
		{
			ru: 'Для обеспечения качества дистанционного образования в настоящее время во всех вузах страны улучшена цифровая инфраструктура, усилена мощность серверов и скорость интернета, повышена цифровая компетентность преподавателей. 100% вузов имеют доступ к цифровым образовательным платформам LMS. 92% вузов обеспечили наличие мобильной версии LMS.',
			kz: 'Қашықтықтан білім беру сапасын қамтамасыз ету үшін еліміздің барлық жоғары оқу орындарында цифрлық инфрақұрылым жетілдіріліп, серверлердің қуаты мен интернет жылдамдығы арттырылды, оқытушылардың цифрлық құзыреттілігі артты. Университеттердің 100% LMS сандық білім беру платформаларына қол жеткізе алады. Университеттердің 92%-ы LMS-тің мобильді нұсқасын ұсынды.',
		},
	],
	comment_obrazovanie_1_2023: [
		{
			ru: 'Министерством образования и науки совместно с НАО «Talap» ведется работа по обеспечению колледжей цифровым контентом. На сегодняшний день на базе НАО «Talap» сформирован каталог с более 20 тыс. цифровыми ресурсами. Данный каталог постоянно пополняется новой информацией, ссылками на образовательные и методические ресурсы. ',
			kz: 'Білім және ғылым министрлігі «Талап» ҰАО-мен бірлесіп, колледждерді цифрлық контентпен қамтамасыз ету жұмыстарын жүргізуде. Бүгінгі таңда «Талап» ҰАО базасында 20 мыңнан астам цифрлық ресурстары бар каталог қалыптастырылды. Бұл каталог жаңа ақпараттармен, оқу-әдістемелік ресурстарға сілтемелермен үнемі жаңартылып отырады',
		},
		{
			ru: 'В июне 2021 года между МОН, МЦРИАП и крупнейшими операторами связи страны подписан Меморандум о сотрудничестве, согласно которому обеспечен бесплатный доступ ко всем образовательным ресурсам. Учителями, учениками и родителями в процессе дистанционного обучения использовались различные казахстанские образовательные платформы, информационные системы, мессенджеры (WhatsApp, Telegram и др.), специализированные сайты.\n',
			kz: '2022 жылдың маусымында Білім және ғылым министрлігі, ICRIAP және елдің ірі байланыс операторлары арасында Ынтымақтастық туралы меморандумға қол қойылды, оған сәйкес барлық білім беру ресурстарына тегін қол жеткізу қарастырылған. Мұғалімдер, оқушылар және ата-аналар қашықтан оқыту үдерісінде әртүрлі қазақстандық білім беру платформаларын, ақпараттық жүйелерді, жедел мессенджерлерді (WhatsApp, Telegram және т.б.), арнайы сайттарды пайдаланды.\n',
		},
	],
	comment_obrazovanie_2_2023: [
		{
			ru: 'Посредством портала «Электронное правительство» автоматизированы государственные услуги по приему документов в десткие сады, школ, колледж и вуз. Автоматизация бизнес-процессов упрощает документооборот, очередность в центрах обслуживания населения, сокращает затраты граждан на сбор документов (в год порядка 1 млрд тг). Также создана платформа online.edu.kz для размещения видеоуроков учителями, преподавателями.\n',
			kz: '«Электрондық үкімет» порталы арқылы балабақшаларда, мектептерде, колледждерде және жоғары оқу орындарында құжаттарды қабылдаудың автоматтандырылған мемлекеттік қызметтері. Бизнес-процестерді автоматтандыру құжат айналымын, халыққа қызмет көрсету орталықтарындағы кезектерді жеңілдетеді, азаматтардың құжаттарды жинауға кететін шығынын азайтады (жылына шамамен 1 млрд. теңге). Мұғалімдердің бейнесабақтарын орналастыру үшін online.edu.kz платформасы да құрылған.\n',
		},
		{
			ru: 'Цифровые образовательные платформы автоматизированы и прежде всего обеспечили доступ педагогов и обучающихся к образовательному контенту, онлайн расписанию, ведению электронного журнала, проверки заданий и выставления оценок, проведение учебных занятий в режиме видеоконференции, тестирования и проведение онлайн экзаменов. \n',
			kz: 'Цифрлық білім беру платформалары автоматтандырылған және ең алдымен мұғалімдер мен студенттерге білім беру мазмұнына, онлайн кестеге, электронды журнал жүргізуге, тапсырмаларды тексеруге және бағалауға, бейнеконференция арқылы оқу сабақтарын өткізуге, тестілеуге және онлайн емтихандарды өткізуге мүмкіндік береді.\n',
		},
	],
	comment_obrazovanie_3_2023: [
		{
			ru: 'Педагогами сформирован качественный образовательный контент с более 24 тысячами цифровыми ресурсами, в т.ч. 13 757 видеоуроков, 4 005 электронных учебников, 6 338 дополнительных материалов. Данный каталог постоянно пополняется новыми образовательными и методическими ресурсами.\n',
			kz: 'Мұғалімдер 24 мыңнан астам сандық ресурстары бар жоғары сапалы білім беру мазмұнын жасады, соның ішінде. 13757 бейнесабақ, 4005 электронды оқулық, 6338 қосымша материалдар. Бұл каталог жаңа оқу-әдістемелік ресурстармен үнемі толықтырылып отырады.\n',
		},
		{
			ru: 'В 2022 году 351 выпускник сдавали ЕНТ на английском языке. Средний балл выпускников, сдающих ЕНТ на английском языке, составил 84,8 балла. Ежегодно увеличивается количество обучающихся, сдающих экзамены IELTS и SAT.\n',
			kz: '2022 жылы 351 түлек ҰБТ-ны ағылшын тілінде тапсырды. Ағылшын тілінде ҰБТ тапсыратын түлектердің орташа балы 84,8 баллды құрады. IELTS және SAT емтихандарын тапсыратын студенттер саны жыл сайын артып келеді.\n',
		},
	],
	comment_obrazovanie_4_2023: [
		{
			ru: 'Ежегодно выделяется 1000 грантов на подготовку педагогов на английском языке обучения по 4 специальностям ЕНЦ « Физика»,  «Информатика», « Химия»,  «Биология»\n',
			kz: '«Физика», «Информатика», «Химия», «Биология» ОӘК-нің 4 мамандығы бойынша ағылшын тілінде мұғалімдерді даярлауға жыл сайын 1000 грант бөлінеді.\n',
		},
		{
			ru: 'В 2022-2023 учебном году в 3864 школах ведется обучение предметам на английском языке, в т.ч. в 2119 сельских школах, из них с полным погружением 439 школы, с частичным погружением – 3425. Количество обучающихся по предметам ЕНЦ на английском языке составляет 243 324 школьников. \n',
			kz: '2022-2023 оқу жылында 3864 мектепте пәндер ағылшын тілінде оқытылады, соның ішінде. 2119 ауыл мектебінде, оның ішінде 439 толық суға шомылу мектебінде, 3425 ішінара суға түсу мектебінде.\n',
		},
	],
	comment_obrazovanie_5_2023: [
		{
			ru:
				'26 вузов (из них 4 национальных, 15 – государственных, 1 международный, 6 – частных) осуществляли обучение в рамках подготовки кадров с высшим образованием по педагогическим группам образовательных программ на английском языке по 4 специальностям естественно-научного цикла (ЕНЦ): «Физика», «Информатика», «Химия», «Биология».\n' +
				'Работа по поэтапному переходу на трехъязычное образование продолжается. ',
			kz:
				'Жаратылыстану циклінің 4 мамандығы бойынша ағылшын тілінде білім беру бағдарламаларының педагогикалық топтарында жоғары білімі бар кадрларды даярлау шеңберінде 26 университет (оның ішінде 4 ұлттық, 15 мемлекеттік, 1 халықаралық, 6 жекеменшік) оқытуды жүзеге асырды: « Физика», «Информатика», «Химия», «Биология».\n' +
				'\n' +
				'Үш тілде білім беруге кезең-кезеңімен көшу жұмыстары жалғасуда.\n',
		},
		{
			ru: 'На сегодня одаренным детям предоставляют образовательные гранты зарубежные вузы. В настоящее время победители и призеры олимпиад поступают в вузы на основании результатов ЕНТ. Также им и педагогам, наставникам планируется учредить единовременные денежные премии. Это будет стимулом для подрастающего поколения, а также сократит отток талантов в зарубежные страны.\n',
			kz: 'Бүгінде шетел университеттері дарынды балаларға білім гранттарын береді. Қазіргі таңда олимпиада жеңімпаздары мен жүлдегерлері ҰБТ қорытындысы бойынша жоғары оқу орындарына түсуде. Сондай-ақ оларға және ұстаздарға, тәлімгерлерге біржолғы ақшалай сыйлықақы белгілеу жоспарлануда. Бұл өскелең ұрпақ үшін стимул болады, сонымен қатар таланттардың шет елдерге кетуін азайтады.\n',
		},
	],
	comment_obrazovanie_6_2023: [
		{
			ru: 'В целом за годы независимости в стране построено порядка 1900 школ. Однако с учетом демографического роста все еще сохраняется дефицит ученических мест (2022 год – 264 тыс. мест, прогноз к 2025 году – 993 тыс. мест).',
			kz: 'Жалпы, Тәуелсіздік жылдарында елімізде 1900-ге жуық мектеп салынды. Дегенмен, демографиялық өсуді ескере отырып, әлі де болса студенттік орындардың тапшылығы байқалады (2022 – 264 мың орын, 2025 жылға болжам – 993 мың орын)\n',
		},
		{
			ru: 'МО РК на системной основе проводятся работы по повышению качества подготовки кадров и высшего образования в целом. Интеграция образования, науки и производства становится решающим фактором развития и роста конкурентоспособности национальной экономики.\n',
			kz: 'Қазақстан Республикасы Қорғаныс министрлігі мамандарды даярлау және жалпы жоғары білім беру сапасын арттыру бойынша жүйелі жұмыс жүргізуде. Білім, ғылым және өндірістің интеграциясы ұлттық экономиканың бәсекеге қабілеттілігінің дамуы мен өсуінің шешуші факторына айналуда.\n',
		},
	],
	comment_obrazovanie_7_2023: [
		{
			ru: 'Новые образовательные программы направлены на формирование гибких профессиональных навыков (soft skills, hard skills) с учетом требований работодателей, профессиональных стандартов, а также современных трендов в области образования. В результате данной работы отмечается сближение подготовки кадров к требованиям и реалиям рынка труда путем внедрения практикоориентированного обучения и принципов дуальной технологии.\n',
			kz: 'Жаңа білім беру бағдарламалары жұмыс берушілердің талаптарын, кәсіби стандарттарды, сондай-ақ білім беру саласындағы заманауи тенденцияларды ескере отырып, икемді кәсіптік дағдыларды (софт-білік, қиын дағдылар) дамытуға бағытталған. Осы жұмыстардың нәтижесінде тәжірибеге бағытталған оқытуды және дуальды технология қағидаттарын енгізу арқылы кадрларды даярлаудың еңбек нарығының талаптары мен шындығына жақындауы байқалады.\n',
		},
		{
			ru: 'В рамках проекта Закона РК «О внесении изменений и дополнений в некоторые законодательные акты по вопросам защиты прав ребенка» (далее – Законопроект), инициированного депутатами Мажилиса Парламента РК, министерством подготовлен проект Правил профилактики травли (буллинга) среди несовершеннолетних. Основной целью Правил является решения конфликтных ситуаций между учащимися и/или педагогами в рамках медиативного подхода. Проект правил разрабатывался совместно представителями родительской, педагогической и экспертной общественности. \n',
			kz: 'Қазақстан Республикасы Парламенті Мәжілісінің депутаттары бастамашылық еткен «Кейбір заңнамалық актілерге баланың құқықтарын қорғау мәселелері бойынша өзгерістер мен толықтырулар енгізу туралы» Қазақстан Республикасы Заңының жобасы (бұдан әрі – Заң жобасы) шеңберінде Қазақстан Республикасының Министрлігі кәмелетке толмағандар арасындағы қорлаудың алдын алу қағидасының жобасын дайындады. Ереженің негізгі мақсаты студенттер және/немесе мұғалімдер арасындағы жанжалды жағдайларды медиативті тәсіл аясында шешу болып табылады. Ереженің жобасын ата-аналар, педагогикалық және сарапшылық қоғамдастықтың өкілдері бірлесіп әзірледі.\n',
		},
	],
	comment_obrazovanie_8_2023: [
		{
			ru: 'Продолжается реализация проекта «Бесплатное техническое и профессиональное образование для всех». В рамках проекта ежегодно в колледжи принимаются 95 тыс. человек, уже более 300 тыс. молодых людей получили первую рабочую специальность бесплатно, из них почти 60% – это молодежь из социально-уязвимых слоев населения.\n',
			kz: '«Баршаға арналған тегін техникалық және кәсіптік білім» жобасын жүзеге асыру жалғасуда. Жоба аясында жыл сайын колледждерге 95 мың адам қабылданады, 300 мыңнан астам жас алғашқы жұмыс мамандығын тегін алып үлгерді, оның 60 пайызға жуығы халықтың әлеуметтік осал топтарындағы жастар.\n',
		},
		{
			ru: 'В Казахстане совершаются первые шаги к предоставлению академической самостоятельности колледжам, что несомненно, повысит качество образовательных программ. Теперь колледжи смогут совместно с работодателями самостоятельно определять содержание образовательных программ, сроки обучения и размещать их в реестре образовательных программ.\n',
			kz: 'Қазақстанда колледждерге академиялық дербестік беру бойынша алғашқы қадамдар жасалуда, бұл білім беру бағдарламаларының сапасын арттырары сөзсіз. Енді колледждер жұмыс берушілермен бірлесіп, білім беру бағдарламаларының мазмұнын, оқу мерзімін өз бетінше анықтап, білім беру бағдарламаларының тізіліміне орналастыра алады.\n',
		},
	],
	comment_obrazovanie_9_2023: [
		{
			ru: 'В целях сокращения оттока молодежи в зарубежные страны прорабатывается ряд проектов, направленных на поддержку и модернизацию инфраструктуры вузов, создание и трансформацию вузов в университеты мирового уровня.\n',
			kz: 'Жастардың шет мемлекеттерге кетуін азайту мақсатында университеттердің инфрақұрылымын қолдауға және жаңғыртуға, жоғары оқу орындарын құруға және әлемдік деңгейдегі университеттерге айналдыруға бағытталған бірқатар жобалар әзірленуде.\n',
		},
		{
			ru: 'Из года в год укрепляются позиции казахстанских вузов в международном образовательном пространстве. В рейтинге QS-2022 позиции заняли 14 казахстанских вузов, в рейтинге Times Higher Education – 3 вуза. Казахстанский вуз КазНУ им. аль-Фараби в очередной раз вошел в топ-200 лучших университетов мира (175 позиция). Еще 2 вуза, как ЕНУ им. Л.Н. Гумилева (328 место) и Южно-Казахстанский университет им. М.Ауэзова (482 место) закрепились в топ-500 рейтинга QS.\n',
			kz: 'Жылдан жылға қазақстандық жоғары оқу орындарының халықаралық білім кеңістігіндегі орны нығайып келеді. QS-2022 рейтингінде 14 қазақстандық университет, Times Higher Education рейтингінде 3 университет орын алды. атындағы ҚазҰУ Қазақ университеті. әл-Фараби тағы да әлемнің үздік 200 университетінің қатарына енді (175-орын). ЕҰУ сияқты тағы 2 университет. Л.Н. Гумилев (328-орын) және Оңтүстік Қазақстан университеті. М.Әуезова (482-орын) QS рейтингінің 500-дік қатарынан орын алды.\n',
		},
	],
	comment_obrazovanie_10_2023: [
		{
			ru: 'Вузы Казахстана активно включены в международные проекты. В 2022 году отечественные вузы приняли участие в реализации более 300 международных проектов. Одним из ключевых направлений является развитие совместных программ с выдачей двух дипломов. Сегодня вузами реализуется около 300 совместных образовательных программ и двудипломных программ. Увеличилось количество программ на английском языке, растет число иностранных студентов. Так в 2022 учебном году в вузах Казахстана обучались 40188 зарубежных студентов\n',
			kz: 'Қазақстанның жоғары оқу орындары халықаралық жобаларға белсенді қатысуда. 2022 жылы отандық университеттер 300-ден астам халықаралық жобаны жүзеге асыруға қатысты. Негізгі бағыттардың бірі – екі диплом берумен бірлескен бағдарламалар жасау. Бүгінгі таңда университеттер 300-ге жуық бірлескен білім беру бағдарламалары мен қос дипломдық бағдарламаларды жүзеге асыруда. Ағылшын тіліндегі бағдарламалар саны көбейді, шетелдік студенттер саны артып келеді. Мәселен, 2022 оқу жылында Қазақстанның жоғары оқу орындарында 40188 шетелдік студент білім алды.\n',
		},
		{
			ru: 'В июне 2021 года проведена конференция министров образования стран Центральной Азии в г. Туркестан, по итогам которой принята Декларация о создании единого Центрально-Азиатского пространства высшего образования. Цель конференции — сближение систем высшего образования стран Центральной Азии.\n',
			kz: '2021 жылдың маусым айында Түркістан қаласында Орталық Азия елдері білім министрлерінің конференциясы өтіп, оның қорытындысы бойынша Орталық Азияның бірыңғай жоғары білім кеңістігін құру туралы Декларация қабылданды. Конференцияның мақсаты – Орталық Азия елдерінің жоғары білім беру жүйелерін жақындастыру.\n',
		},
	],
	comment_obrazovanie_11_2023: [
		{
			ru: '',
			kz: '',
		},
		{
			ru: '',
			kz: '',
		},
	],
	comment_obrazovanie_12_2023: [
		{
			ru: 'В период пандемии организации высшего и послевузовского образования обеспечили 100% переход на дистанционное обучение. Для обеспечения качества дистанционного образования в настоящее время во всех вузах страны улучшена цифровая инфраструктура, усилена мощность серверов и скорость интернета, повышена цифровая компетентность преподавателей.\n',
			kz: 'Пандемия кезінде жоғары және жоғары оқу орнынан кейінгі білім беру ұйымдары қашықтықтан оқытуға 100% көшуді қамтамасыз етті. Қашықтықтан білім беру сапасын қамтамасыз ету үшін еліміздің барлық жоғары оқу орындарында цифрлық инфрақұрылым жетілдіріліп, серверлердің қуаты мен интернет жылдамдығы арттырылды, оқытушылардың цифрлық құзыреттілігі артты.\n',
		},
		{
			ru: 'В рамках реализации п. 65 ДКНО «Введение к 2025 году не менее 50 тыс. новых мест в общежитиях колледжей и университетов» планируется поэтапный ввод мест в общежитиях (2021 г. – 10 тыс., 2022 г. – 20 тыс., 2023 – 30 тыс., 2024 – 40 тыс., 2025 г. – 50 тыс.).\n',
			kz: '«2025 жылға қарай колледждер мен ЖОО жатақханаларында кемінде 50 мың жаңа орын енгізу» ДҚҰО-ның 65-тармағын іске асыру шеңберінде жатақханалардағы орындарды кезең-кезеңімен енгізу жоспарлануда (2021 ж. – 10 мың, 2022 жылы – 20 мың. , 2023 - 30 мың, 2024 - 40 мың, 2025 - 50 мың).\n',
		},
	],

	comment_banki_10_2022: [
		{
			ru: 'Председатель Национального банка РК Галымжан Пирматов сообщил, что менять предельную сумму ипотеки по программе «7-20-25» в Казахстане не будут, передает корреспондент МИА «Казинформ».  «Что касается предложений, то пока нет планов по изменению условий программы. В том числе не планируется повышать предельный размер суммы ипотеки до уровня, установленного для Астаны, Алматы, Шымкента, Караганды и других городов», - сказал Галымжан Пирматов на брифинге в Нацбанке.\n',
			kz:
				'ҚР Ұлттық банк төрағасы Ғалымжан Пірматов Қазақстанда «7-20-25» бағдарламасы бойынша ипотеканың шекті сомасын өзгертпейтіндерін айтты, деп хабарлайды ҚазАқпарат тілшісі.\n' +
				'\n' +
				'«Ұсыныстарға келетін болсақ, әзірге бағдарлама шарттарын өзгерту жоспарда жоқ. Оның ішінде ипотеканың шекті сомасын Астана, Алматы, Шымкент, Қарағанды ​​және басқа қалалар үшін белгіленген деңгейге дейін арттыру жоспарланбайды», - деді Ғалымжан Пірматов Ұлттық банкте өткен брифингте.\n',
		},
		{
			ru: 'На 1 октября 2022 года объем кредитов банковского сектора экономике достиг 23,1 трлн тенге. С начала года показатель вырос на 12,7%. На 1 февраля сумма составляла 20,4 трлн тенге, сообщает корреспондент центра деловой информации Kapital.kz со ссылкой на данные Национального банка.\n',
			kz: '2022 жылғы 1 қазандағы жағдай бойынша банк секторының экономикаға берген несиелерінің көлемі 23,1 трлн теңгеге жетті. Жыл басынан бері көрсеткіш 12,7%-ға өсті. 1 ақпандағы жағдай бойынша сома 20,4 трлн теңгені құрады, деп хабарлайды Kapital.kz іскерлік ақпарат орталығының тілшісі Ұлттық банк деректеріне сілтеме жасап.\n',
		},
	],
	comment_banki_11_2022: [
		{
			ru: '3 130 мерных слитков общим весом 125,66 кг продано банками второго уровня и отдельными небанковскими обменными пунктами в октябре 2022 года. \n',
			kz: '2022 жылдың қазан айында екінші деңгейлі банктер және жеке банктік емес айырбастау пункттері жалпы салмағы 125,66 кг 3 130 құйма құйма сатты\n',
		},
		{
			ru: 'Новым членом совета директоров Банка Развития Казахстана стал Нурлан Байбазаров, ранее занявший пост председателя правления банка\n',
			kz: 'Бұған дейін банктің басқарма төрағасы қызметін атқарған Нұрлан Байбазаров Қазақстан Даму банкі директорлар кеңесінің жаңа мүшесі болды.\n',
		},
	],
	comment_banki_12_2022: [
		{
			ru: 'За 10 месяцев 2022 года Национальный банк Казахстана (далее – НБК) и банки второго уровня выявили 250 поддельных денежных знаков (247 банкнот и 3 монеты) на сумму 739 650 тенге. Это на 55% меньше, чем за аналогичный период 2021 года (457 фальшивых денежных знаков)\n',
			kz: '2022 жылдың 10 айында Қазақстан Ұлттық Банкі (бұдан әрі – ҚРҰБ) және екінші деңгейлі банктер 739 650 теңге сомасында 250 жалған банкноттарды (247 банкнот және 3 монета) анықтады. Бұл 2021 жылдың сәйкес кезеңімен салыстырғанда 55%-ға аз (457 жасанды банкнот)\n',
		},
		{
			ru:
				'Более 1,5 млн вкладчиков подтвердили своё согласие на получение компенсации в размере 10% по тенговым депозитам. Для этого они подали заявления в банки, сообщает пресс-служба Казахстанского фонда гарантирования депозитов. \n' +
				'На сегодняшний день число вкладчиков, подтвердивших своё согласие, составляет 30% от общего количества вкладчиков, соответствующего условиям программы.\n',
			kz:
				'1,5 миллионнан астам салымшы теңгедегі депозиттер бойынша 10 пайыз көлемінде өтемақы алуға келісімін растады. Ол үшін олар банктерге өтінім берді, деп хабарлайды Қазақстанның депозиттерге кепілдік беру қорының баспасөз қызметі.\n' +
				'\n' +
				'Бүгінгі күні келісімін растаған салымшылардың саны бағдарлама шарттарына сәйкес келетін салымшылардың жалпы санының 30%-ын құрайды.\n',
		},
	],
	comment_banki_1_2023: [
		{
			ru: 'На 1 сентября регуляторный собственный капитал банков Казахстана составил 5,8 трлн тенге, что на 10,1% больше, чем годом ранее. На протяжении последних пяти лет показатель растёт в среднем на 14% в год, сообщает Ranking.\n',
			kz: '1 қыркүйектегі жағдай бойынша Қазақстандағы банктердің реттеуші меншікті капиталы 5,8 трлн теңгені құрады, бұл бір жыл бұрынғы көрсеткіштен 10,1%-ға артық. Соңғы бес жылда бұл көрсеткіш жылына орта есеппен 14%-ға өсті, деп хабарлайды Ranking.\n',
		},
		{
			ru: 'Теперь казахстанцы, использующие банковские карты Visa, могут переводить средства по номеру телефона между разными банками. В системе мобильных переводов участвуют 11 банков и платёжных систем: ForteBank, Jusan Bank, Алтын банк, Halyk Bank, Нурбанк, "Казпочта", RBK Bank, Банк ЦентрКредит, Eurasian Bank, Simply, Freedom Bank.\n',
			kz: 'Енді Visa банк карталарын пайдаланатын қазақстандықтар әртүрлі банктер арасында телефон нөмірі бойынша ақша аудара алады. Мобильді аударым жүйесіне 11 банк пен төлем жүйесі қатысады: ForteBank, Jusan Bank, Altyn Bank, Halyk Bank, Nurbank, Kazpost, RBK Bank, Bank CenterCredit, Eurasian Bank, Simply, Freedom Bank.\n',
		},
	],
	comment_banki_2_2023: [
		{
			ru: 'Основатель Astana Group Нурлан Смагулов откроет свой банк в Казахстане. Об этом предприниматель заявил на форуме Kazakhstan Growth Forum 2022 в Алматы. Банк будет заниматься только автокредитованием и работать только онлайн, без физических отделений.\n',
			kz: 'Astana Group құрылтайшысы Нұрлан Смағұлов Қазақстанда өз банкін ашады. Бұл туралы кәсіпкер Алматыда өтіп жатқан Kazakhstan Growth Forum 2022 форумында мәлімдеді. Банк тек автокөлік несиелерімен айналысады және физикалық бөлімшелерсіз онлайн режимінде ғана жұмыс істейді\n',
		},
		{
			ru: 'Доля просроченных банковских потребкредитов устойчиво снижается и на 1 июля 2022г составила 4%. У микрофинансовых организаций тенденция обратная – безнадёжных микрокредитов на 1 июля было уже 10%. Кредиты на сумму менее 150 тысяч тенге составляют 39% портфеля микрофинансовых организаций, а доля заёмщиков таких кредитов – 85%. \n',
			kz: 'Мерзімі өткен банктік тұтыну несиелерінің үлесі тұрақты түрде төмендеп келеді және 2022 жылғы 1 шілдедегі жағдай бойынша 4%-ды құрады. Микроқаржы ұйымдары керісінше тенденцияға ие - 1 шілдедегі жағдай бойынша нашар микрокредиттердің 10%-ы болды. 150 мың теңгеден аз несиелер микроқаржы ұйымдары портфелінің 39%-ын құрайды, ал мұндай несиелерді алушылардың үлесі 85%-ды құрайды.\n',
		},
	],
	comment_banki_3_2023: [
		{
			ru: 'Бывший дочерний банк "Сбера" в Казахстане переименуют в "Береке банк", сообщил председатель правления холдинга "Байтерек" Канат Шарлапаев в интервью Telegram-каналу Shishkin_like.\n',
			kz: 'Сбербанктің Қазақстандағы бұрынғы еншілес банкі "Береке банк" болып өзгереді, деп хабарлады "Бәйтерек" холдингінің басқарма төрағасы Қанат Шарлапаев Shishkin_like Telegram арнасына берген сұхбатында.\n',
		},
		{
			ru: 'Систему единых терминалов для приёма оплаты через QR-код планируют ввести в Казахстане до конца года. Независимо от того, клиентом какого банка вы являетесь, можно будет платить с помощью QR через любой терминал.\n',
			kz: 'Жыл соңына дейін Қазақстанда QR коды арқылы төлемдерді қабылдауға арналған бірыңғай терминалдар жүйесін енгізу жоспарлануда. Сіз қай банктің клиенті болсаңыз да, кез келген терминал арқылы QR арқылы төлем жасай аласыз.\n',
		},
	],
	comment_banki_4_2023: [
		{
			ru: 'В первом полугодии 2022 года чистая прибыль банков Казахстана составила 355 млрд тенге, что на 46,2% ниже показателя аналогичного периода 2021 года, сообщается на сайте Агентства по регулированию и развитию финансового рынка.\n',
			kz: '2022 жылдың бірінші жартыжылдығында Қазақстандағы банктердің таза пайдасы 355 млрд теңгені құрады, бұл 2021 жылдың сәйкес кезеңімен салыстырғанда 46,2%-ға төмен, деп хабарлайды ҚР Қаржы нарығын реттеу және дамыту агенттігінің сайты.\n',
		},
		{
			ru:
				'С 1 июня 2022г  Отбасы банк переходит на способы открытия депозитов, исключающие необходимость приходить в отделение банка. Как сообщается на странице банка в Instagram, исключение составят следующие категории клиентов:\n' +
				'лица, связанные особыми отношениями либо аффилированные с банком;\n' +
				'иностранцы и лица без гражданства;\n' +
				'несовершеннолетние, за которых депозит открывают доверенные лица, представители, опекуны или попечители;\n' +
				'воспитанники детдомов и домов юношества;\n' +
				'лица, имеющие принадлежность/причастность к лицу публичному либо связанному с финансированием терроризма и экстремизма;\n' +
				'лица, которые в соответствии с требованиями банка считаются лицами "с высоким уровнем риска".\n',
			kz:
				'2022 жылдың 1 маусымынан бастап «Отбасы Банкі» банк бөлімшесіне келу қажеттілігін болдырмайтын депозит ашу әдістеріне ауысады. Банктің Instagram парақшасында хабарланғандай, клиенттердің келесі санаттары алынып тасталады:\n' +
				'• банкпен ерекше қатынастармен байланысты немесе аффилиирленген тұлғалар;\n' +
				'• шетелдіктер мен азаматтығы жоқ адамдар;\n' +
				'• депозитті сенім білдірілген адамдар, өкілдер, қорғаншылар немесе қамқоршылар ашатын кәмелетке толмағандар;\n' +
				'• балалар үйлері мен жасөспірімдер үйлерінің тәрбиеленушілері;\n' +
				'• қоғамдық тұлғаға қатысы бар немесе терроризм мен экстремизмді қаржыландырумен байланысты тұлғалар;\n' +
				'• банктің талаптарына сәйкес «жоғары тәуекел» тұлғалары болып саналатын тұлғалар.\n',
		},
	],
	comment_banki_5_2023: [
		{
			ru: 'По результатам исследования, проведенного экспертами авторитетного международного издания Global Finance в период с 1 апреля 2022 года по 31 марта 2022 года, Halyk Bank второй год подряд признан победителем престижной награды в номинации «Лучший Банк для малого и среднего бизнеса в Казахстане 2023» (Best SME Bank in Kazakhstan 2023).\n',
			kz: '2022 жылдың 1 сәуірі мен 2022 жылдың 31 наурызы аралығында беделді халықаралық Global Finance басылымының сарапшылары жүргізген зерттеу нәтижелері бойынша Халық банкі «Шағын және орта бизнеске арналған үздік банк» номинациясы бойынша беделді сыйлықтың жеңімпазы болып танылды. Қазақстанда 2023» (Үздік ШОБ) екінші жыл қатарынан. Банк Қазақстандағы 2023).\n',
		},
		{
			ru: 'Международное рейтинговое агентство S&P Global Ratings повысило рейтинги АО «Kaspi Bank» до «ВВ» с «ВВ -» и до «kzA+» c «kzA». Прогноз по рейтингам – «Стабильный».Агентство отметило, что рейтинг Kaspi Bank отражает проверенную стратегию и прочные рыночные позиции в сегментах бизнес-кредитования, потребительского кредитования и платежей.\n',
			kz: 'S&P Global Ratings халықаралық рейтингтік агенттігі «Kaspi Bank» АҚ рейтингтерін BB-ден BB-ге дейін және «kzA» деңгейінен «kzA+» деңгейіне көтерді. Рейтинг болжамы «Тұрақты» Агенттік Kaspi Bank рейтингі бизнесті несиелендіру, тұтынушылық несиелеу және төлемдер сегменттеріндегі дәлелденген стратегияны және мықты нарықтық ұстанымды көрсететінін атап өтті.\n',
		},
	],
	comment_banki_6_2023: [
		{
			ru: 'Казахстанцам начнут списывать долги: стала известна процедура банкротства Процедура банкротства будет проходить тремя способами: внесудебным, судебным и путем восстановления платежеспособности. В данный момент законопроект находится на рассмотрении в мажилисе. По данным Минфина, на 1 октября 1,4 млн человек задолжали по кредитам 1,44 млрд тенге (свыше 90 дней). Из них 96% – займы до 5 млн тенге.\n',
			kz:
				'Қазақстандықтар қарыздарын өтей бастайды: банкроттық рәсімі белгілі болды\n' +
				'Банкроттық рәсімі үш жолмен жүзеге асырылады: соттан тыс, сот және төлем қабілеттілігін қалпына келтіру. Қазіргі уақытта заң жобасы Мәжілісте қаралуда.\n' +
				'Қаржы министрлігінің мәліметінше, 1 қазандағы жағдай бойынша 1,4 млн адам несие бойынша 1,44 млрд теңге (90 күннен астам) берешегі бар. Оның 96 пайызы 5 миллион теңгеге дейінгі несиелер.\n',
		},
		{
			ru: 'По состоянию на 1 ноября 2022 года банковский сектор Республики Казахстан представлен 21 банками второго уровня, из которых 12 банков с иностранным участием, в том числе 9 дочерних банков.\n',
			kz: '2022 жылғы 1 қарашадағы жағдай бойынша Қазақстан Республикасының банк секторы 21 екінші деңгейлі банктермен ұсынылған, оның ішінде 12-і шетелдік қатысуы бар банктер, оның ішінде 9 еншілес банктер.\n',
		},
	],
	comment_banki_7_2023: [
		{
			ru: 'Кредиты в национальной валюте за октябрь 2022 года увеличились на 2,0%, до 19,7 трлн тенге, кредиты в иностранной валюте уменьшились на 0,7% до 1,8 трлн тенге.\n',
			kz: 'Ұлттық валютадағы несиелер 2022 жылғы қазанда 2,0%-ға өсіп, 19,7 трлн теңгені құрады, шетел валютасындағы несиелер 0,7%-ға азайып, 1,8 трлн теңгені құрады.\n',
		},
		{
			ru: 'Кредиты юридическим лицам составили 8,1 трлн тенге, увеличившись за октябрь 2022 года на 0,8%. С начала текущего года рост данного показателя составил 4,4% (за аналогичный период 2021 года – рост на 2,4%).\n',
			kz: 'Заңды тұлғаларға берілген несиелер 2022 жылғы қазанда 0,8%-ға ұлғайып, 8,1 трлн теңгені құрады. Ағымдағы жылдың басынан бері бұл көрсеткіштің өсімі 4,4%-ды құрады (2021 жылдың сәйкес кезеңінде – 2,4%-ға өсті).\n',
		},
	],
	comment_banki_8_2023: [
		{
			ru: 'Кредиты физических лиц составили 13,4 трлн тенге, увеличившись за октябрь 2022 года на 2,5% (с начала текущего года рост на 24,6%). Рост кредитов физическим лицам обусловлен увеличением ипотечного кредитования на 3,9% до 4,4 трлн тенге в результате продолжения реализации льготных государственных ипотечных программ, а также ростом потребительских займов на 1,5% до 7,3 трлн тенге.\n',
			kz: 'Жеке тұлғаларға берілген несиелер 2022 жылғы қазанда 2,5%-ға өсті (осы жылдың басынан бері 24,6%-ға өсті) 13,4 трлн теңгені құрады. Жеке тұлғаларға берілген несиелердің өсуі жеңілдетілген мемлекеттік ипотекалық бағдарламаларды іске асыруды жалғастыру нәтижесінде ипотекалық несиелендірудің 3,9%-ға 4,4 трлн теңгеге дейін артуы, сондай-ақ тұтынушылық несиелердің 1,5%-ға 7,3 трлн теңгеге дейін ұлғаюымен байланысты болды.\n',
		},
		{
			ru: 'В Национальном банке пересмотрели прогноз по годовой инфляции на 2023 год. Финрегулятор, комментируя изменение базовой ставки, сообщил, что в текущем году, по прогнозам, годовая инфляция сложится в пределах 20-21 процента. По оценкам, пик годовой инфляции прогнозируется в I квартале 2023 года.\n',
			kz: 'Ұлттық банк инфляцияның 2023 жылға арналған жылдық болжамын қайта қарады. Қаржы реттеушісі базалық мөлшерлеменің өзгеруіне түсініктеме бере отырып, биылғы жылы болжам бойынша жылдық инфляция 20-21 пайыз аралығында болатынын айтты. Есептер бойынша жылдық инфляцияның шыңы 2023 жылдың бірінші тоқсанында болжануда.\n',
		},
	],
	comment_banki_9_2023: [
		{
			ru: 'В 2022 году по итогам оценки качества активов у 4 банков − АО «АТФБанк», АО «Евразийский Банк», АО «Банк ЦентрКредит», АО «Нурбанк» − были выявлены убытки в 131 млрд тенге. У 3 банков − АО «АТФБанк», АО «Евразийский Банк», АО «Банк ЦентрКредит» − остаток прежних убытков составлял 78 млрд тенге. С учетом оценки качества активов совокупный убыток вырос до 209 млрд тенге. При этом новым участником программы стал АО «Нурбанк», который получил поддержку в размере 47 млрд тенге также под 4%.\n',
			kz: '2022 жылы активтер сапасын бағалау нәтижелері бойынша 4 банк – «АТФБанк» АҚ, «Еуразиялық банк» АҚ, «Банк ЦентрКредит» АҚ, «Нұрбанк» АҚ 131 млрд теңге шығын көрсетті. 3 банк бойынша – «АТФБанк» АҚ, «Еуразиялық банк» АҚ, «Банк ЦентрКредит» АҚ – бұрынғы шығынның сальдосы 78 млрд теңгені құрады. Активтердің сапасын бағалауды ескере отырып, жалпы шығын 209 млрд теңгеге дейін өсті. Бұл ретте «Нұрбанк» АҚ 47 млрд теңге көлеміндегі қолдауға ие болған бағдарламаның жаңа қатысушысы болды.\n',
		},
		{
			ru: 'БЦК оперативно купил Альфа-Банк, который теперь будет работать под брендом Еco Center Bank. С учетом инновационного направления Альфа-Банка в работе с микробизнесом (независимые телеграм-каналы называли его лучшим инновационным банком Казахстана для МСБ) БЦК получает прекрасный триггер для экспоненциального роста бизнеса. \n',
			kz: 'ЦКБ дереу Альфа-Банкті сатып алды, ол енді Eco Center Bank брендімен жұмыс істейтін болады. Альфа-Банктің микробизнеспен жұмыс жасаудағы инновациялық бағытын ескере отырып (тәуелсіз телеграм-арналар оны ШОБ үшін Қазақстандағы ең жақсы инновациялық банк деп атады), ЦБК бизнестің экспоненциалды өсуі үшін тамаша триггер алады.\n',
		},
	],
	comment_banki_10_2023: [
		{
			ru: 'Банковский сектор в Казахстане работает хорошо, имея достаточный запас капитала. Собственный капитал на январь 2022 года составил 4,6 триллиона тенге, что на 14,1% больше, чем на конец аналогичного периода прошлого года. Коэффициент достаточности основного капитала (k1) составил 19,3%, а коэффициент достаточности собственного капитала(k2) составил 23,3%. Эти показатели являются средними по системе и значительно превышают нормативы, установленные законодательством.\n',
			kz: 'Қазақстандағы банк секторы тиісті капитал буферімен жақсы жұмыс істеуде. 2022 жылдың қаңтарындағы жағдай бойынша меншікті капитал 4,6 трлн теңгені құрады, бұл өткен жылдың сәйкес кезеңінің соңындағы көрсеткіштен 14,1%-ға артық. Капитал жеткіліктілік коэффициенті (k1) 19,3%, ал меншікті капиталдың жеткіліктілік коэффициенті (k2) 23,3% болды. Бұл көрсеткіштер жүйе бойынша орташа және заңмен белгіленген нормалардан айтарлықтай асып түседі.\n',
		},
		{
			ru: 'Банки второго уровня также имеют значительный запас высоколиквидных активов, которые составляют около 11,6 триллионов тенге, или 31,3% активов. Высокий уровень запаса капитала и высоколиквидных активов позволяет банкам абсорбировать возникающие шоки на финансовом рынке и обслуживать свои обязательства в полном объеме.\n',
			kz: 'Екінші деңгейлі банктерде де өтімділігі жоғары активтердің айтарлықтай қоры бар, ол шамамен 11,6 трлн теңгені немесе активтердің 31,3%-ын құрайды. Капитал қорының және жоғары өтімді активтердің жоғары деңгейі банктерге қаржы нарығындағы қалыптасып жатқан күйзелістерді жұтып, өз міндеттемелерін толық көлемде атқаруға мүмкіндік береді.\n',
		},
	],
	comment_banki_11_2023: [
		{
			ru: 'По росту вкладов населения (по итогам 2022 года они увеличились на 22,8%) основной прирост пришелся как раз на крупные БВУ. Бенефициарами роста стали Народный банк, Отбасы банк и Kaspi bank. Вместе с ДБ Береке банк на начало 2022 года они аккумулировали 72,2% всех вкладов населения (на начало 2022 года показатель составлял 69,5%).\n',
			kz: 'Халық депозиттерінің өсімі бойынша (2022 жылдың қорытындысы бойынша олар 22,8%-ға өсті), негізгі өсім екінші деңгейдегі ірі банктерде болды. Өсудің бенефициарлары Халық Банкі, Otbasy Bank және Kaspi bank болды. «Береке Банк» ДБ-мен бірлесіп, 2022 жылдың басында олар халықтың барлық депозиттерінің 72,2%-ын жинақтаған (2022 жылдың басында бұл көрсеткіш 69,5%).\n',
		},
		{
			ru:
				'Bank RBK – девятый по размерам банк в Казахстане с активами 1,27 трлн тенге, что на 27% больше (8-е место по приросту), чем 996,35 млрд годом ранее. Чистая прибыль составила 14,44 млрд тенге против 12,4 млрд в предыдущем году.\n' +
				'Единственным владельцем Bank RBK является ТОО «КСС Финанс», конечный бенефициар которого – Владимир Ким (№1 рейтинга богатейших бизнесменов Forbes Kazakhstan).\n',
			kz:
				'Bank RBK 1,27 трлн теңге активтерімен Қазақстандағы тоғызыншы ірі банк болып табылады, бұл бір жыл бұрынғы 996,35 миллиардтан 27%-ға көп (өсу бойынша 8-ші). Таза пайда өткен жылғы 12,4 млрд теңгеге қарсы 14,44 млрд теңгені құрады.\n' +
				'\n' +
				'Bank RBK жалғыз иесі «KSS Finance» ЖШС болып табылады, оның түпкілікті бенефициары Владимир Ким (Forbes Қазақстандағы ең бай бизнесмендер рейтингінде №1).\n',
		},
	],
	comment_banki_12_2023: [
		{
			ru:
				'АО «Ситибанк Казахстан» – единственный американский банк, представленный в Казахстане. 100% акций банка принадлежат Citibank, National Association (Citibank, N.A.). Финансовый институт предлагает полный спектр банковских услуг корпорациям, финансовым институтам и национальным компаниям.\n' +
				'По итогам 2022 года активы банка составили 1,1 трлн тенге (10-е место), что на 20,78% больше (9-е место по приросту) 912 млрд годом ранее. За год банк заработал 49,99 млрд тенге (6-е место). Ситибанк Казахстан занимает третье место по показателям ROE и ROA – 34,8 и 5% соответственно.\n',
			kz:
				'«Ситибанк Қазақстан» АҚ – Қазақстандағы жалғыз американдық банк. Банк акцияларының 100%-ы Citibank, National Association (Citibank, N.A.) иелігінде. Қаржы институты корпорацияларға, қаржы институттарына және ұлттық компанияларға банктік қызметтердің толық кешенін ұсынады.\n' +
				'\n' +
				'2022 жылдың қорытындысы бойынша банк активтері 1,1 триллион теңгені (10-орын) құрады, бұл бір жыл бұрынғы 912 миллиардқа 20,78 пайызға артық (өсу бойынша 9-орын). Жыл ішінде банк 49,99 млрд теңге табыс тапты (6-орын). Citibank Kazakhstan ROE және ROA бойынша үшінші орында - сәйкесінше 34,8% және 5%.\n',
		},
		{
			ru:
				'АО «Банк Фридом Финанс Казахстан»  По итогам 2022 года банк стал лидером сектора по росту активов. За год они увеличились в 4,7 раза, с 84,7 млрд тенге в 2020-м до 398 млрд. Чистая прибыль по итогам прошлого года составила 6,29 млрд тенге против 1,69 млрд в предыдущем году.\n' +
				'30 декабря 2020 года Freedom Holding Corp., принадлежащая Тимуру Турлову (№7 рейтинга богатейших бизнесменов Forbes Kazakhstan), выкупила 100% простых акций Банка Kassa Nova у Forte Bank. Общая стоимость сделки составила $52,9 млн. 1 февраля 2021 года банк перерегистрирован в АО «Банк Фридом Финанс Казахстан».\n',
			kz:
				'«Bank Freedom Finance Kazakhstan» АҚ 2022 жылдың соңында банк активтерінің өсімі бойынша сектор көшбасшысы болды. Бір жыл ішінде олар 4,7 есе өсіп, 2020 жылғы 84,7 млрд теңгеден 398 млрд теңгеге дейін өсті.Таза пайда өткен жылдың аяғында өткен жылғы 1,69 млрд теңгеден 6,29 млрд теңгені құрады.\n' +
				'2020 жылдың 30 желтоқсанында Тимур Турловқа тиесілі Freedom Holding Corp. (Forbes Қазақстандағы ең бай бизнесмендер рейтингінде №7) Forte Bank-тен Kassa Nova Банкінің 100% жай акцияларын сатып алды. Мәміленің жалпы сомасы $52,9 млн құрады.2021 жылдың 1 ақпанында банк «Bank Freedom Finance Kazakhstan» АҚ болып қайта тіркелді.\n',
		},
	],

	comment_gornodobicha_10_2022: [
		{
			ru: 'Объем производства товарной продукции минерально-сырьевого комплекса Казахстана составляет 44% общего промышленного производства, в том числе 39% приходится на долю нефтегазового комплекса и 5% на добычу минерального сырья, кроме нефти и газа.\n',
			kz: 'Қазақстанның минералдық-шикізат кешенінің тауарлық өнімдерін өндіру көлемі жалпы өнеркәсіп өндірісінің 44%-ын құрайды, оның ішінде 39%-ы мұнай-газ кешенінің үлесіне және 5%-ы мұнайды қоспағанда, минералды шикізатты өндіруге келеді. және газ.\n',
		},
		{
			ru: 'Доля перерабатывающих производств  составляет 11% - для цветной металлургии и 5% - для черной. Во внешнеторговом обороте страны экспорт продукции, связанной с минерально-сырьевым комплексом (включая сплавы металлов и нефтепродукты), в стоимостном выражении составляет более 80% от общереспубликанского экспорта, в том числе на долю нефтегазовой продукции приходится более 60% и на долю продукции горнорудной отрасли и металлургии около 20%.\n',
			kz:
				'өңдеу объектілерінің үлесі 11% - түсті\n' +
				'металлургия және 5% қара. Еліміздің сыртқы сауда айналымында экспорт\n' +
				'минералдық-шикізат кешеніне жататын өнімдер (соның ішінде қорытпалар\n' +
				'металдар мен мұнай өнімдері), құндық мәнде 80%-дан астам\n' +
				'жалпыұлттық экспорттан, оның ішінде мұнай мен газ үлесінен\n' +
				'өндіру 60%-дан астам және тау-кен өнеркәсібі өнімдерінің үлесіне және\n' +
				'металлургия шамамен 20%.\n',
		},
	],
	comment_gornodobicha_11_2022: [
		{
			ru: 'В Казахстане приоритетным направлением инвестирования являются объекты недропользования по углеводородному сырью, финансовые вложения в разведку и добычу нефти составляют более 70% общего объема. Меньшая доля инвестиций приходится на твердые полезные ископаемые.\n',
			kz: 'Қазақстанда инвестицияның басым бағыты көмірсутек шикізаты бойынша жер қойнауын пайдалану объектілері болып табылады, мұнайды барлауға және өндіруге қаржылық инвестициялар жалпы көлемнің 70%-дан астамын құрайды. Инвестициялардың аз үлесі қатты пайдалы қазбаларға тиесілі.\n',
		},
		{
			ru: 'Особенностью перспективного развития отрасли полезных ископаемых в Казахстане является приоритет для более углубленной переработки минерального сырья и выпуск продукции с высокой добавленной стоимостью. То есть, приветствуется инвестирование не только в разработку полезных ископаемых, но в большей степени - на их переработку.\n',
			kz:
				'Минералдық өнеркәсіптің перспективалық дамуының ерекшелігі\n' +
				'Қазақстан тереңірек өңдеуге басымдық береді\n' +
				'минералды шикізат және жоғары қосылған құны бар өнімдерді өндіру.\n' +
				'Яғни, пайдалыны дамытуға ғана емес инвестиция құюға болады\n' +
				'пайдалы қазбалар, бірақ көбірек дәрежеде - оларды өңдеу үшін.\n',
		},
	],
	comment_gornodobicha_12_2022: [
		{
			ru: 'АО "КазТрансОйл" является национальным оператором по магистральному нефтепроводу, а также одной из крупнейших нефтепроводных компаний Республики Казахстан, оказывающей услуги по транспортировке нефти более 80 нефтедобывающим компаниям Казахстана.\n',
			kz: '«ҚазТрансОйл» АҚ магистральдық мұнай құбырының ұлттық операторы, сондай-ақ Қазақстандағы 80-нен астам мұнай өндіруші компанияларға мұнай тасымалдау қызметін көрсететін Қазақстан Республикасындағы ірі мұнай құбыры компанияларының бірі болып табылады.\n',
		},
		{
			ru: 'Горнодобывающая и нефтегазовая промышленность – один из стратегически важных секторов экономики Республики Казахстан. Государством на постоянной основе проводятся масштабные мероприятия по развитию горнодобывающей и нефтегазовой промышленности, способствующие значительному увеличению притока финансовых средств в экономику страны.\n',
			kz:
				'Тау-кен және мұнай-газ өнеркәсібі стратегиялық маңызды салалардың бірі болып табылады\n' +
				'Қазақстан Республикасының экономикасы. Мемлекет ел экономикасына қаржы ресурстарының ағынын айтарлықтай арттыруға ықпал ететін тау-кен және мұнай-газ өнеркәсібін дамыту бойынша ауқымды шараларды ұдайы жүзеге асыруда.\n',
		},
	],
	comment_gornodobicha_1_2023: [
		{
			ru: 'Компания KAZ Minerals ориентирована на добычу медных руд и имеет в своём распоряжении несколько действующих рудников. Недавно, компания приступила к промышленной разработке крупного месторождения Бозшаколь, что в два раза увеличило объём производимой компанией меди. Следующим проектом, ожидающим запуска в производство, является рудник Актогай. Компания АМС отвечала за исследование геологических, геотехнических и горных аспектов в обоих проектах.\n',
			kz: 'KAZ Minerals мыс кендерін өндіруге бағытталған және бірнеше белсенді кеніштері бар. Жуырда компания ірі Бозшакөл кен орнын өнеркәсіптік игеруге кірісіп, кәсіпорынның мыс өндіру көлемін екі есеге арттырды. Келесі өндірісті күтіп тұрған жоба – Ақтоғай кеніші. AMC екі жобаның геологиялық, геотехникалық және тау-кен аспектілерін зерттеуге жауапты болды.\n',
		},
		{
			ru: 'Компания Rio Tinto, в сотрудничестве с Казгеология и АО «Тау-Кен Самрук», занимаются разведкой и разработкой меднопорфировых местрождений.\n',
			kz: '«Рио Тинто» «Қазгеология» және «Тау-Кен Самұрық» АҚ-мен бірлесіп, порфирлі мыс кен орындарын барлаумен және игерумен айналысады.\n',
		},
	],
	comment_gornodobicha_2_2023: [
		{
			ru: 'Компания Iluka Resouces, в сотрудничестве с Казгеология, занимается разведкой местрождений минеральных песков.\n',
			kz: 'Iluka Resouses компаниясы Қазгеологиямен бірлесіп, минералды құм кен орындарын барлаумен айналысады.\n',
		},
		{
			ru: 'Казахстан является второй страной в мире по наличию разведанных запасов урана, сосредоточенных в шести урановых районах: Шу-Сарысу, Сырдарья, Северный Казахстан, Каспий, Балхаш и Или.\n',
			kz: 'Қазақстан алты уран аймағында: Шу-Сарысу, Сырдария, Солтүстік Қазақстан, Каспий маңы, Балқаш және Іледе шоғырланған дәлелденген уран қоры бойынша әлемде екінші ел.\n',
		},
	],
	comment_gornodobicha_3_2023: [
		{
			ru: 'Главным производителем угля выступает международная металлургическая компания ArcelorMittal. В центральной части Казахстана находятся Карагандинский и Экибастузский каменноугольные бассейны. Здесь открытым и шахтным способом добываются коксующиеся и высококалорийные энергетические угли.\n',
			kz: 'АрселорМиттал халықаралық металлургиялық компания негізгі көмір өндіруші болып табылады. Қазақстанның орталық бөлігінде Қарағанды ​​және Екібастұз көмір бассейндері бар. Мұнда кокстелетін және жоғары калориялы термиялық көмірлер ашық және шахталық әдістермен өндіріледі.\n',
		},
		{
			ru: 'Карагандинский бассейн включает в себя в основном подземные угольные шахты, в которых производится добыча высококачественного коксующегося угля с зольностью от 10 до 35%. Бассейн имеет большие запасы газа, и с давних времен в нем добывается метан. Здесь же действует самый крупный в мире угольный разрез «Богатырь» мощностью 50 млн. т угля в год.\n',
			kz: 'Қарағанды ​​бассейніне негізінен күлділігі 10-нан 35%-ға дейін жоғары сапалы кокстелетін көмір өндіретін жерасты көмір шахталары кіреді. Бассейнде үлкен газ қоры бар, одан метан көне заманнан бері алынған. Сондай-ақ қуаттылығы жылына 50 миллион тонна көмірді құрайтын әлемдегі ең ірі «Богатырь» көмір кеніші жұмыс істейді.\n',
		},
	],
	comment_gornodobicha_4_2023: [
		{
			ru: 'Природные ресурсы Казахстана, называемые полиметаллами, содержат ценные медные соединения, а также вкрапления цинка, свинца, золота, серебра. Эти минеральные ресурсы Казахстана добываются на Рудном Алтае и в Текели.\n',
			kz: 'Қазақстанның полиметалдар деп аталатын табиғи ресурстарында бағалы мыс қосылыстары, сонымен қатар мырыш, қорғасын, алтын, күміс қосындылары бар. Қазақстанның бұл пайдалы қазбалары Рудный Алтай мен Текеліде өндіріледі.\n',
		},
		{
			ru: 'Фосфориты добывают на юге Казахстана. В богатстве этим нерудным ископаемым РК едва не обошла США. На данный момент в мировом списке по добыче фосфоритов государство занимает второе место после Штатов.\n',
			kz: 'Фосфориттер Қазақстанның оңтүстігінде өндіріледі. Бұл металл емес пайдалы қазбалардың байлығы бойынша Қазақстан АҚШ-ты басып озды десе де болады. Қазіргі уақытта фосфориттерді өндіру бойынша әлемдік тізімде штат АҚШ-тан кейін екінші орында тұр.\n',
		},
	],
	comment_gornodobicha_5_2023: [
		{
			ru: 'Холдинг «Казахмыс» – одна из крупнейших компаний в мире, занимающаяся горнодобывающей промышленностью и цветной металлургией. «Казахмыс» специализируется на добыче и переработке меди, а также выпуске золота и серебра в слитках, серной кислоты, селена, теллурида меди, свинцовой пыли.\n',
			kz: 'Қазақмыс Холдингі – тау-кен және түсті металлургиямен айналысатын әлемдегі ең ірі компаниялардың бірі. Қазақмыс мыс өндіру мен өңдеуге, сондай-ақ алтын және күміс құймаларын, күкірт қышқылын, селен, мыс теллуриді, қорғасын шаңын өндіруге маманданған.\n',
		},
		{
			ru: 'Газовая отрасль Казахстана сегодня – одна из самых динамично-развивающихся в отечественной экономике. Добыча газа растет, создаются новые газоперерабатывающие мощности, развивается сеть газопроводов. Казахстан занимает 22 место в мире и 3 место среди стран СНГ после России и Туркменистана по запасам газа. Утвержденные извлекаемые запасы составляют 3,8 трлн.м3, в том числе попутного газа – 2,2 трлн.м3, свободного газа – 1,6 трлн.м3.\n',
			kz: 'Қазақстанның газ саласы бүгінде отандық экономикадағы ең серпінді дамып келе жатқан салалардың бірі болып табылады. Газ өндіру өсуде, жаңа газ өңдеу қуаттары құрылуда, газ құбырлары желісі игерілуде. Қазақстан газ қоры бойынша әлемде 22-ші, ТМД елдері арасында Ресей мен Түркіменстаннан кейін 3-ші орында. Бекітілген алынатын қорлар 3,8 трлн м3 құрайды, оның ішінде 2,2 трлн м3 ілеспе газ және 1,6 трлн м3 бос газ.\n',
		},
	],
	comment_gornodobicha_6_2023: [
		{
			ru: 'Основные доказанные запасы газа страны сосредоточены в Западном Казахстане на нефтяных месторождениях Карачаганак, Тенгиз и Кашаган (81%). По данным Минэнерго, в 2022 году добыча сырого газа составила 53,8 млрд кубометров. План на 2023 год — 54,5 млрд кубометров\n',
			kz: 'Елдің негізгі барланған газ қоры Батыс Қазақстанда Қарашығанақ, Теңіз және Қашаған мұнай кен орындарында (81%) шоғырланған. Энергетика министрлігінің мәліметінше, 2022 жылы шикі газ өндірісі 53,8 млрд текше метрді құрады. 2023 жылға арналған жоспар 54,5 млрд текше метр.\n',
		},
		{
			ru: 'Крупнейшим месторождением в Западно-Казахстанской области является Карачаганакское с извлекаемыми запасами жидкого углеводородного сырья около 320 млн тонн и газа более 450 млрд куб м. Еще одним перспективным регионом с точки зрения нефтегазового потенциала является Актюбинская область. Здесь открыто около 25 месторождений. Наиболее значимым геологическим открытием в этом регионе является Жанажольская группа месторождений с извлекаемыми запасами нефти и конденсата около 170 млн тонн. \n',
			kz: 'Батыс Қазақстан облысындағы ең ірі кен орны сұйық көмірсутектердің алынатын қоры 320 миллион тоннаға жуық және газдың 450 миллиард текше метрден асатын Қарашығанақ кен орны болып табылады.Мұнай-газ әлеуеті жағынан тағы бір перспективті аймақ Ақтөбе облысы болып табылады. Мұнда 25-ке жуық кен орны ашылған. Бұл аймақтағы ең маңызды геологиялық жаңалық мұнай мен конденсаттың алынатын қоры 170 миллион тоннаға жуық Жаңажол кен орындары тобы болып табылады.\n',
		},
	],
	comment_gornodobicha_7_2023: [
		{
			ru: ' В географическом плане по состоянию на 01 апреля 2022 года наибольшее количество действующих организаций горнодобывающей и нефтегазовой промышленности находятся в г. Алматы (580 организаций или 17% от общего количества действующих предприятий горнодобывающей и нефтегазовой промышленности), г. Нур-Султан (496 организаций или 14%) и в Карагандинской области (357 организаций или 10%).\n',
			kz:
				'Географиялық тұрғыдан 2022 жылдың 01 сәуіріндегі жағдай бойынша тау-кен өндіру және мұнай-газ саласындағы жұмыс істейтін ұйымдардың ең көп саны осында орналасқан.\n' +
				'Алматы қаласы (580 ұйым немесе жұмыс істеп тұрған кәсіпорындардың жалпы санынан 17%)\n' +
				'тау-кен және мұнай-газ өнеркәсібі), Нұр-Сұлтан (496 ұйым немесе\n' +
				'14% және Қарағанды ​​облысында (357 ұйым немесе 10%).\n',
		},
		{
			ru: 'Несмотря на 100%-ную долю экспорта сырой нефти в структуре внешней торговли, в 2020 году снизился в целом общий объем экспорта товаров из Казахстана. Основной причиной стало снижение цен на нефть, которая занимает основную долю в горнодобывающей и нефтегазовой промышленности.\n',
			kz:
				'Сыртқы сауда құрылымындағы шикі мұнай экспортының 100% үлесіне қарамастан, 2022 ж.\n' +
				'Қазақстаннан тауар экспортының жалпы көлемі қысқарды. Негізгі себеп тау-кен және мұнай-газ салаларында негізгі үлесті алатын мұнай бағасының төмендеуі болды.\n',
		},
	],
	comment_gornodobicha_8_2023: [
		{
			ru: 'За последние пять лет и первый квартал 2022 года в структуре инвестиций в основные средства предприятий горнодобывающей и нефтегазовой промышленности преобладающим источником финансирования являются собственные средства предприятий, которые составляют более 90% от общего объема инвестиций в предприятия горнодобывающей и нефтегазовой промышленности.\n',
			kz: 'Соңғы бес жылда және 2022 жылдың бірінші тоқсанында тау-кен өнеркәсібі және мұнай-газ өнеркәсібі кәсіпорындарының негізгі капиталына инвестициялар құрылымында қаржыландырудың басым көзі кәсіпорындардың меншікті қаражаты болып табылады, олардың үлесі 90%-дан астам. тау-кен және мұнай-газ өнеркәсібіндегі кәсіпорындарға инвестицияның жалпы көлемінің.\n',
		},
		{
			ru: 'Горнорудный сектор играет значительную роль в экономике Казахстана. Государство установило схему привлечения прямых зарубежных инвестиций в секторы нефти и твердых полезных ископаемых, богатых сырьевыми ресурсами\n',
			kz:
				'Қазақстан экономикасында тау-кен өнеркәсібі маңызды рөл атқарады.\n' +
				'Мемлекет тікелей шетел инвестицияларын тарту схемасын белгіледі\n' +
				'шикізатқа бай мұнай және қатты пайдалы қазбалардың секторлары\n' +
				'ресурстар\n',
		},
	],
	comment_gornodobicha_9_2023: [
		{
			ru: 'Для того, чтобы эффективно использовать прибыль от минеральных ресурсов в периоды высоких цен на сырье, в Казахстане она полностью направляется в Фонд национального благосостояния (так называемый стабилизационный фонд). В настоящее время экономической рецессии средства фонда используются для инвестирования проектов в сфере диверсификации экономики. Фонд национального благосостояния управляется Правительством Казахстана и Президентом, не являясь предметом обсуждений.\n',
			kz:
				'Пайдалы қазбалардан түскен табысты тиімді пайдалану мақсатында\n' +
				'шикізат бағасының жоғары болған кезеңдеріндегі ресурстар, Қазақстанда ол толығымен\n' +
				'Ұлттық әл-ауқат қорына (деп аталатын) түседі\n' +
				'тұрақтандыру қоры). Қазіргі экономикалық құлдырау дегенді білдіреді\n' +
				'қаражат әртараптандыру саласындағы жобаларды инвестициялауға жұмсалады\n' +
				'экономика. Үкімет басқаратын Ұлттық әл-ауқат қоры\n' +
				'Қазақстан мен Президент талқыланбайды.\n',
		},
		{
			ru:
				'Нефтяные и минеральные ресурсы Казахстана\n' +
				'Кашаган. Крупное шельфовое месторождение до сих пор находится в разработке. Первую станцию под названием «Восток-1» установили здесь в 2000 году. Расположен Кашаган недалеко от Атырау. За год в этой местности добывают около 8,35 млн. тонн нефти. Для промышленности в сложной природной зоне разработчикам пришлось установить искусственные острова и добывать нефть из больших глубин (до 4800 м).\n',
			kz:
				'Қазақстанның мұнай және минералды ресурстары\n' +
				'• Қашаған. Үлкен теңіз кен орны әлі де игерілу үстінде. Мұнда 2000 жылы «Восток-1» деп аталатын алғашқы станция орнатылған. Қашаған Атырауға жақын жерде орналасқан. Бұл ауданда жылына 8,35 миллион тоннаға жуық мұнай өндіріледі. Күрделі табиғи аймақтағы өнеркәсіп үшін әзірлеушілер жасанды аралдар орнатып, үлкен тереңдіктен (4800 м дейін) мұнай өндіруге мәжбүр болды.\n',
		},
	],
	comment_gornodobicha_10_2023: [
		{
			ru:
				'Тенгиз.\n' +
				'Залежи нефти обнаружили в 1979 году в 350 км от Атырау. На сегодня Тенгиз занимает второе место по запасам нефти. Ресурсы Казахстана в этой местности исчисляются 3,1 млрд. тонн черного золота. В год здесь добывают около 25 млн. тонн.\n',
			kz:
				'Теңіз.\n' +
				'• Мұнай кен орындары 1979 жылы Атыраудан 350 км жерде табылған. Бүгінде Теңіз мұнай қоры бойынша екінші орында тұр. Қазақстанның бұл саладағы ресурстары 3,1 млрд тонна қара алтынды құрайды. Мұнда жылына шамамен 25 миллион тонна өндіріледі.\n',
		},
		{
			ru:
				'Карачаганак\n' +
				'В провинции недалеко от города Аксай добывают нефть, газ и газовый конденсат. Карачаганак открыли в 1979 году. Запасы месторождения составляют 1,2 млрд. тонн нефти.\n' +
				'В 2002 году из Карачаганака экспортировали максимальное количество природных залежей. А разработчики получили награду за высокую безопасность и охрану окружающей среды.\n',
			kz:
				'Қарашығанақ\n' +
				'Мұнай, газ және газ конденсаты Ақсай қаласына жақын провинцияда өндіріледі. Қарашығанақ 1979 жылы ашылған. Кен орнының қоры 1,2 млрд тонна мұнайды құрайды.\n' +
				'2002 жылы Қарашығанақтан табиғи кен орындарының максималды көлемі экспортталды. Ал әзірлеушілер жоғары қауіпсіздік пен қоршаған ортаны қорғау үшін марапатқа ие болды.\n',
		},
	],
	comment_gornodobicha_11_2023: [
		{
			ru:
				'Узень \n' +
				'На полуострове Мангышлак находится крупное месторождение. Нефтегазовые залежи открыли в 1961 году. Запасы черного золота исчисляются 1,1 млрд. тонн. Рекордный уровень по добыче сырья был в 1975 году и составил 16, 3 млн. тонн.\n',
			kz:
				'Өзен\n' +
				'Маңғышлақ түбегінде үлкен кен орны бар. Мұнай және газ кен орындары 1961 жылы ашылды. Қара алтынның қоры 1,1 млрд тоннаны құрайды. Шикізат өндірудің рекордтық деңгейі 1975 жылы болды және 16,3 млн тоннаны құрады.\n',
		},
		{
			ru: 'Богатства Казахстана не исчерпываются только нефтью и газом. В стране также добывают минералы. Республика занимает первое место в мире по запасам барита, цинка, вольфрама.\n',
			kz: 'Қазақстанның байлығы тек мұнай мен газбен шектелмейді. Елімізде пайдалы қазбалар да өндіріледі. Республика барит, мырыш, вольфрам қоры жөнінен дүние жүзінде бірінші орында.\n',
		},
	],
	comment_gornodobicha_12_2023: [
		{
			ru:
				'Железо.\n' +
				'Запасы железной руды принесли стране третье место на мировом рынке — около 16,6 млрд. тонн железа. Природные ресурсы Северного Казахстана расположены в Костанайской области. Большое значение имеют Соколовское и Качарское месторождения.\n',
			kz: 'Темір рудасының қоры елімізді әлемдік нарықта үшінші орынға алып келді – шамамен 16,6 миллиард тонна темір. Солтүстік Қазақстанның табиғи ресурстары Қостанай облысында орналасқан. Соколовское және Качарское кен орындарының маңызы зор.\n',
		},
		{
			ru: '',
			kz: '',
		},
	],

	comment_stroitelniy_10_2022: [
		{
			ru: 'Строительный сектор в Казахстане остается одним из наиболее привлекательных направлений для инвестирования. На долю местного строительного комплекса приходится до 6% всего ВВП Казахстана. В плане занятости строительный сектор обеспечивает около 700 тыс. рабочих мест, или 7,9% от общей занятости в экономике.\n',
			kz: 'Қазақстандағы құрылыс секторы инвестиция үшін ең тартымды салалардың бірі болып қала береді. Жергілікті құрылыс кешенінің үлесі Қазақстанның жалпы ішкі өнімінің 6%-ға дейінін құрайды. Жұмыспен қамту бойынша құрылыс секторы 700 мыңға жуық жұмыс орнын немесе экономикадағы жалпы жұмыспен қамтудың 7,9%-ын қамтамасыз етеді.\n',
		},
		{
			ru: 'Объем строительства должен поддерживаться инвестициями в рамках Государственной программы развития инфраструктуры «Нұрлы жол» на 2020-2025 годы, цель которой — создание конкурентоспособной транспортной инфраструктуры, улучшение технологической и институциональной среды, развитие услуг перевозки и транзита по Казахстану.\n',
			kz: 'Болжамды кезеңдегі құрылыс көлемі бәсекеге қабілетті көлік инфрақұрылымын құру, технологиялық және институционалдық ортаны жақсарту, көлік пен тасымалдауды дамыту болып табылатын 2020-2025 жылдарға арналған «Нұрлы жол» мемлекеттік инфрақұрылымды дамыту бағдарламасы шеңберіндегі инвестициялар есебінен қамтамасыз етілуге ​​тиіс. Қазақстандағы транзиттік қызметтер.\n',
		},
	],
	comment_stroitelniy_11_2022: [
		{
			ru:
				'За четыре месяца 2022 года в Казахстане введены в эксплуатацию новые объекты площадью более четырех миллионов квадратных метров. В строительную сферу вложено 863 млрд тенге. Отрасль развивалась за счет возведения нежилых зданий, строительства нефтяных и газопроводов.\n' +
				'Подробнее на Kursiv Media: https://kz.kursiv.media/2022-06-14/stroitelnyj-rynok-kazahstana-prevysil-863-mlrd-tenge/\n',
			kz:
				'2022 жылдың төрт айында Қазақстанда төрт миллион шаршы метрден асатын жаңа нысандар іске қосылды. Құрылыс саласына 863 млрд теңге инвестиция тартылды. Өнеркәсіп тұрғын емес үйлер салу, мұнай және газ құбырларын салу арқылы дамыды.\n' +
				'Толығырақ Kursiv Media сайтында: https://kz.kursiv.media/2022-06-14/stroitelnyj-rynok-kazahstana-prevysil-863-mlrd-tenge/\n',
		},
		{
			ru:
				'По данным из статсборников Бюро нацстатистики РК, динамику денежного прироста объемов строительства в диапазоне 10% обеспечили именно строительно-монтажные работы, которые занимают 88% рынка (759 млрд тенге). Капитальный и текущий ремонт не показали хороших результатов – плюс 0,2% и минус 4,4% соответственно. В январе – апреле прошлого года ремонты демонстрировали более существенный денежный прирост.\n' +
				'Подробнее на Kursiv Media: https://kz.kursiv.media/2022-06-14/stroitelnyj-rynok-kazahstana-prevysil-863-mlrd-tenge/\n',
			kz:
				'Қазақстан Республикасы Ұлттық статистикалық бюросының статистикалық жинақтарының деректері бойынша құрылыс көлемінің ақшалай өсу динамикасы 10% диапазонында дәл нарықтың 88% алып жатқан құрылыс-монтаждау жұмыстарымен қамтамасыз етілді. теңгені құрады (759 млрд. теңге). Күрделі және ағымдағы жөндеу жақсы нәтиже бермеді – сәйкесінше плюс 0,2% және минус 4,4%. Өткен жылдың қаңтар-сәуір айларында жөндеу жұмыстары айтарлықтай ақшалай пайда көрсетті.\n' +
				'Толығырақ Kursiv Media сайтында: https://kz.kursiv.media/2022-06-14/stroitelnyj-rynok-kazahstana-prevysil-863-mlrd-tenge/\n',
		},
	],
	comment_stroitelniy_12_2022: [
		{
			ru:
				'Судя по структуре распределения заказов между участниками, строительный рынок в стране принадлежит частным казахстанским (78,8%) и иностранным компаниям (21%). Государственные предприятия есть, но они закрывают слишком маленькие объемы (0,2%).\n' +
				'Подробнее на Kursiv Media: https://kz.kursiv.media/2022-06-14/stroitelnyj-rynok-kazahstana-prevysil-863-mlrd-tenge/\n',
			kz:
				'Қатысушылар арасындағы тапсырыстарды бөлу құрылымына қарағанда, елдегі құрылыс нарығы жеке қазақстандық (78,8%) мен шетелдік компанияларға (21%) тиесілі. Мемлекеттік кәсіпорындар бар, бірақ олар тым аз көлемде жабылады (0,2%).\n' +
				'Толығырақ Kursiv Media сайтында: https://kz.kursiv.media/2022-06-14/stroitelnyj-rynok-kazahstana-prevysil-863-mlrd-tenge/\n',
		},
		{
			ru: 'С 2010 году в Казахстане существенно вырос такой показатель строительного сектора как ввод общей площади жилья. Если в 2010 года этот показатель составлял 6,4 млн. м2, то к 2022 году он вырос почти в три раза и составил более 17 млн. м2.\n',
			kz: '2010 жылдан бастап Қазақстанда тұрғын үйлердің жалпы алаңын пайдалануға беру сияқты құрылыс секторының көрсеткіші айтарлықтай өсті. Егер 2010 жылы бұл көрсеткіш 6,4 млн м2 болса, 2022 жылға қарай ол үш есе дерлік өсіп, 17 млн ​​м2-ден астамды құрады.\n',
		},
	],
	comment_stroitelniy_1_2023: [
		{
			ru: 'Стратегия развития Республики Казахстан до 2050 года главной целью ставит создание общества благоденствия на основе сильного государства, развитой экономики и возможностей всеобщего труда, вхождение Казахстана в тридцатку самых развитых стран мира. В рамках программы строительной отрасли ставится задача по модернизации транспортной и социальной инфраструктуры.\n',
			kz: 'Қазақстан Республикасының 2050 жылға дейінгі даму стратегиясында қуатты мемлекет, дамыған экономика және жалпыға бірдей еңбек мүмкіндіктері негізінде өркендеген қоғам құру, Қазақстанның әлемнің ең дамыған отыз елінің қатарына кіруі басты мақсат болып табылады. Құрылыс индустриясы бағдарламасы аясында көлік және әлеуметтік инфрақұрылымды жаңғырту міндеті тұр.\n',
		},
		{
			ru: 'Государственная программа инфраструктурного развития «Нұрлы жол» на 2020 – 2025 годы направлена на создание эффективной и конкурентоспособной транспортной инфраструктуры, развитие транзита и транспортных услуг, совершенствование технологической и институциональной среды. До 2025 года программой планируется реализация 112 инфраструктурных проектов на общую сумму 5,5 трлн тг. (около 12 млрд. долл. США).\n',
			kz: '«Нұрлы жол» мемлекеттік инфрақұрылымды дамытудың 2020-2025 жылдарға арналған бағдарламасы тиімді және бәсекеге қабілетті көлік инфрақұрылымын құруға, транзиттік және көліктік қызметтерді дамытуға, технологиялық және институционалдық ортаны жақсартуға бағытталған. 2025 жылға дейін бағдарламада жалпы құны 5,5 триллион теңгені құрайтын 112 инфрақұрылымдық жобаны жүзеге асыру жоспарланған. (шамамен 12 млрд. АҚШ доллары).\n',
		},
	],
	comment_stroitelniy_2_2023: [
		{
			ru: 'По данным Союза строителей Казахстана, за 7 месяцев 2022 года в сравнении с аналогичным периодом 2021 года больше всего выросли в цене железобетонные изделия, кирпич керамический, кирпич силикатный, сухие строительные смеси, напольные покрытия и теплоизоляционные изделия, индекс цен по которым находится в коридоре 113,92-137,03 (другими словами, рост в коридоре 13,9%-37% в годовом выражении).\n',
			kz: 'Қазақстан құрылысшылар одағының мәліметі бойынша 2022 жылдың 7 айында 2021 жылдың сәйкес кезеңімен салыстырғанда темірбетон бұйымдары, керамикалық кірпіш, силикат кірпіш, құрғақ құрылыс қоспалары, еден жабындары және жылу оқшаулағыш бұйымдар, баға индексі 113-дәлізде тұр, баға ең жоғары өсті.92-137,03 (басқаша айтқанда, дәліздегі өсім жылдық мәнде 13,9% -37%).\n',
		},
		{
			ru: 'Устойчивый рост демонстрируют цены на пиломатериалы, инертные материалы, цемент, блоки из ячеистого бетона и блоки стеновые. А вот стоимость арматуры за 1 тонну снизилась с 448 тыс. тенге до 340 тыс. тенге, или на 24,1%. Следует отметить, что в 2020 году средняя цена 1 тонны арматуры равнялась 213 тыс. тенге. Строительные материалы и изделия, являясь основой для строительства, обычно формируют до 60% стоимости жилого здания в сметах строительных объектов.\n',
			kz: 'Тұрақты өсім ағаш материалдарының, толтырғыштардың, цементтің, ұялы бетон блоктарының және қабырға блоктарының бағасынан көрінеді. Бірақ 1 тонна арматураның құны 448 мың теңгеден 340 мың теңгеге немесе 24,1 пайызға төмендеді. Айта кету керек, 2020 жылы 1 тонна арматураның орташа бағасы 213 мың теңгені құрады. Құрылысқа негіз болатын құрылыс материалдары мен бұйымдары, әдетте, құрылыс жобаларының сметасында тұрғын үй құрылысының құнының 60% дейін құрайды.\n',
		},
	],
	comment_stroitelniy_3_2023: [
		{
			ru: 'Сезонность спроса на стройматериалы важна при рассмотрении строительного рынка. Так как строительство зависит от атмосферных и климатических условий и действие этих факторов не позволяет осуществлять строительство объектов круглогодично, строительным периодом являются лето, часть весны и осени. Для условий большей части Казахстана этот период составляет 7-8 месяцев. Следовательно, спрос на строительные материалы характеризуется сезонностью.\n',
			kz: 'Құрылыс нарығын қарастыру кезінде құрылыс материалдарына сұраныстың маусымдылығы маңызды. Құрылыс атмосфералық-климаттық жағдайларға байланысты болғандықтан және осы факторлардың әрекеті объектілерді жыл бойына салуға мүмкіндік бермейтіндіктен, құрылыс кезеңі жаз, көктем мен күздің бір бөлігі. Қазақстанның басым бөлігінің жағдайы үшін бұл мерзім 7-8 айды құрайды. Демек, құрылыс материалдарына сұраныс маусымдылығымен сипатталады.\n',
		},
		{
			ru: 'Основным фактором замедления развития рынка строительных материалов в Казахстане, учитывая высокую степень износа основных фондов, является сокращение инвестиций в основной капитал, заявил inbusiness.kz глава Союза строителей Казахстана Талгат Ергалиев. В настоящее время, по его информации, товарный рынок Казахстана насыщен строительными материалами, в то же время ассортимент отечественной продукции не полностью удовлетворяет внутренний спрос потребителей.\n',
			kz: 'Қазақстандағы құрылыс материалдары нарығының дамуының баяулауының негізгі факторы, негізгі қорлардың тозуының жоғары дәрежесін ескере отырып, негізгі капиталға инвестицияның қысқаруы болып табылады, деп хабарлады inbusiness.kz сайтына Қазақстан құрылысшылар одағының басшысы Талғат Ерғалиев. . Оның айтуынша, қазіргі уақытта Қазақстанның тауар нарығы құрылыс материалдарымен қаныққан, сонымен бірге отандық өнімдердің ассортименті тұтынушылардың ішкі сұранысын толық қанағаттандырмайды.\n',
		},
	],
	comment_stroitelniy_4_2023: [
		{
			ru: 'Стоимость строительства индивидуального дома площадью от 100 до 150 квадратных метров в агломерации города Нур-Султана варьируется в пределах от 20 млн до 100 млн тенге в зависимости от класса жилья без учета стоимости земли, наружных инженерных сетей и чистовой отделки.\n',
			kz: 'Нұр-Сұлтан агломерациясында ауданы 100-ден 150 шаршы метрге дейінгі жеке тұрғын үй құрылысының құны жер учаскелерін, сыртқы инженерлік желілер мен құрылыстарды есепке алмағанда, тұрғын үй класына байланысты 20 миллионнан 100 миллион теңгеге дейін құрайды. тамаша әрлеу.\n',
		},
		{
			ru: 'По предварительным прогнозам Министерства индустрии и инфраструктурного развития, в период 2022-2025 годов в Казахстане ожидается строительство 62 млн кв м жилья. Кроме того, ведется строительство 5201 государственного  объекта, из которых 2137 возводятся за счет государственных средств.\n',
			kz: 'Индустрия және инфрақұрылымдық даму министрлігінің алдын ала болжамы бойынша, 2022-2025 жылдар аралығында Қазақстанда 62 миллион шаршы метр тұрғын үй салынады деп күтілуде. Сондай-ақ, 5201 мемлекет меншігіндегі нысанның құрылысы жүргізілсе, оның 2137-сі мемлекет қаражаты есебінен салынуда.\n',
		},
	],
	comment_stroitelniy_5_2023: [
		{
			ru:
				'с 1 января 2021 года по 1 мая 2022-го ЕНПФ перевел более 3,1 трлн тенге на спецсчета казахстанцев для использования пенсионных накоплений на улучшение жилищных условий. Исполнены 1 321 017 заявлений вкладчиков. Больше всего направлено жителям Алматы (17,74%), столицы (13,52%), Мангистауской области (10,40%).\n' +
				'Подробнее на Kursiv Media: https://kz.kursiv.media/2022-10-18/stroiteli-kazahstana-ne-uspevajut-za-rostom-cen-na-pervichnoe-zhile/\n',
			kz:
				'2021 жылғы 1 қаңтардан бастап 2022 жылғы 1 мамырға дейін БЖЗҚ тұрғын үй жағдайын жақсартуға зейнетақы жинақтарын пайдалану үшін қазақстандықтардың арнайы шоттарына 3,1 триллион теңгеден астам қаражат аударды. Салымшылардың 1 321 017 өтініші орындалды. Көбісі Алматы (17,74%), астана (13,52%), Маңғыстау облысының (10,40%) тұрғындарына жіберілді.\n' +
				'Толығырақ Kursiv Media сайтында: https://kz.kursiv.media/2022-10-18/stroiteli-kazahstana-ne-uspevajut-za-rostom-cen-na-pervichnoe-zhile/\n',
		},
		{
			ru: 'Сложившаяся импортозависимость бьет по ценам на жилье (в себестоимости жилья стройматериалы занимают до 60%) и в целом негативно сказывается на всей сфере строительства в РК.\n',
			kz: 'Импортқа ағымдағы тәуелділік тұрғын үй бағасына (тұрғын үй құнында құрылыс материалдары 60%-ға дейін) әсер етеді және тұтастай алғанда Қазақстан Республикасының барлық құрылыс секторына теріс әсер етеді.\n',
		},
	],
	comment_stroitelniy_6_2023: [
		{
			ru: 'Крайне высока (50%–90%) доля импорта и в таких категориях, как лесоматериалы, пластмассовые покрытия для пола, стен и потолков, стальные трубы, фитинги и проволока, радиаторы для отопления и счетчики воды, газа и электроэнергии, кирпичи и плитка из глины, и т. д. Более 30% рынка удерживают импортеры в таких категориях, как металлоконструкции, стержни и прутки из стали, шлаковата и минвата, изолирующие изделия из стекла, краски и лаки, и т. д.\n',
			kz: 'Ағаш, пластмасса еден, қабырғалар мен төбелер, болат құбырлар, арматура мен сым, жылыту радиаторлары мен су, газ және электр энергиясына есептегіштер, кірпіш пен плиткалар сияқты санаттардағы импорттың үлесі де өте жоғары (50%-90%). балшықтан және т.б.. Нарықтың 30%-дан астамы металл конструкциялар, болаттан жасалған штангалар мен шыбықтар, қож және минералды мақта, оқшаулағыш шыны бұйымдары, бояулар мен лактар ​​және т.б. сияқты санаттардағы импорттаушыларға тиесілі.\n',
		},
		{
			ru: 'В одном лишь секторе металлопроката по итогам мая текущего года цены импортных поступлений товаров подскочили за год, то есть к маю 2022-го, сразу на 67,7% для горячекатаного плоского проката без покрытия из железа или нелегированной стали шириной 600 мм или более, на 24,1% - для холоднокатаного, на 38,5% - для проката с гальваническим или другим покрытием. И это только одна крупная товарная категория, зависимость рынка РК от импорта которой весьма ощутима.\n',
			kz: 'тек металл прокат секторында ағымдағы жылдың мамыр айының қорытындысы бойынша тауарлардың импорттық түсімдерінің бағасы жыл бойына, яғни 2021 жылдың мамырына қарай темір жабыны жоқ немесе легірленбеген ыстықтай прокатқа бірден 67,7%-ға жоғарылады. ені 600 мм және одан жоғары болат, 24,1%-ға – суықтай илектеу үшін, 38,5%-ға – мырышталған немесе басқа жабыны бар болат үшін. Ал бұл бір ғана ірі тауар санаты, оның қазақстандық нарығының импортқа тәуелділігі айтарлықтай байқалады.\n',
		},
	],
	comment_stroitelniy_7_2023: [
		{
			ru: 'Как и любой другой сектор экономики, строительная отрасль подвергается воздействию внешних и внутренних факторов. Её состояние нельзя назвать стабильным из-за ежедневного изменения ситуации. В последние месяцы, май, июнь, июль, цены снижались. Это происходило, из-за того что на фоне январских событий застройщики и частные продавцы сильно подняли цены, чтобы защититься от возможных рисков. Однако ожидания не оправдались — риски не наступили, рынок движется плавно. \n',
			kz: 'Экономиканың кез келген басқа секторы сияқты құрылыс саласы да сыртқы және ішкі факторлардың әсеріне ұшырайды. Жағдайдың күнделікті өзгеруіне байланысты оның жағдайын тұрақты деп атауға болмайды. Соңғы айларда мамыр, маусым, шілде айларында баға төмендеуде. Бұған қаңтар оқиғасы аясында құрылыс салушылар мен жеке сатушылар ықтимал тәуекелдерден қорғану үшін бағаны күрт көтергені себеп болды. Дегенмен, күтулер ақталған жоқ – тәуекелдер келмеді, нарық бірқалыпты жүріп жатыр.\n',
		},
		{
			ru: 'Порядка 60 % действующих на рынке строительных компаний не могут уложиться в сроки по контрактам и вынуждены их сдвигать, некоторые до полугода.\n',
			kz: 'Нарықта жұмыс істейтін құрылыс компанияларының 60%-ға жуығы келісім-шарттар бойынша белгіленген мерзімдерді орындай алмайды және оларды кейбіреулері алты айға дейін ауыстыруға мәжбүр\n',
		},
	],
	comment_stroitelniy_8_2023: [
		{
			ru: 'В РК, с одной стороны, объёмы ипотечного кредитования растут (январь-июль 2022 года к аналогичному периоду прошлого года. с другой — тревожно сократилось количество сделок, а отдельные застройщики запустили скидочные программы и организуют круглые столы с требованием создать программу льготной ипотеки. \n',
			kz: 'Қазақстанда бір жағынан ипотекалық несиелендіру көлемі артып келеді (өткен жылдың сәйкес кезеңімен салыстырғанда ағымдағы жылдың қаңтар-шілде айлары. – Ескертпе ред.), екінші жағынан транзакциялар саны да үрей туғызарлықтай қысқарды. , ал кейбір әзірлеушілер жеңілдетілген ипотекалық бағдарлама құруды талап етіп, жеңілдік бағдарламаларын іске қосып, дөңгелек үстелдер ұйымдастырды.\n',
		},
		{
			ru: 'Рынок основных видов строительных материалов Казахстана по итогам 2022 года обеспечивает до 5,5% объема производства обрабатывающей промышленности и находится на 5 место среди подотраслей, уступая металлургической промышленности, производству продуктов питания, машиностроению и производству кокса и продуктов нефтепереработки. В общем объеме промышленности рынок основных видов строительных материалов составляет до 2,3%.\n',
			kz:
				'Қазақстанның құрылыс материалдарының негізгі түрлерінің нарығы1 сәйкес\n' +
				'2016 жылдың қорытындысы бойынша өңдеу өнеркәсібінің өндіріс көлемінің 5,5%-ға дейінін қамтамасыз етеді.\n' +
				'өнеркәсіп және кіші секторлар арасында металлургиядан кейін 5-ші орында\n' +
				'өнеркәсіп, тамақ өндірісі, машина жасау және өндіріс\n' +
				'кокс және тазартылған мұнай өнімдері. Жалпы өнеркәсіпте негізгі нарық\n' +
				'құрылыс материалдарының түрлері 2,3%-ға дейін.\n',
		},
	],
	comment_stroitelniy_9_2023: [
		{
			ru: 'В настоящее время сохраняется зависимость от импорта некоторых видов строительной продукции . Среди материалов, производимых отечественными предприятиями и покрывающих потребности внутреннего рынка, можно выделить только строительные растворы и товарный бетон \n',
			kz: 'Қазіргі уақытта құрылыс өнімдерінің жекелеген түрлерінің импортына тәуелділік сақталуда. Отандық кәсіпорындар өндіретін және ішкі нарықтың қажеттілігін өтейтін материалдардың ішінде тек ерітінділер мен дайын бетонды ерекшелеуге болады.\n',
		},
		{
			ru: 'Крупной составляющей современной экономики любой страны выступает промышленность строительных материалов, изделий и конструкций.  В Казахстане, в  состав промышленности строительных материалов входят около 600 предприятий, при этом лишь 30 из них являются крупными, а 100 – средними.\n',
			kz:
				'Кез келген елдің қазіргі экономикасының негізгі құрамдас бөлігі құрылыс материалдары, бұйымдары мен конструкциялары өнеркәсібі болып табылады.\n' +
				'Қазақстанда құрылыс материалдары өнеркәсібі 600-ге жуық кәсіпорынды қамтыса, оның 30-ы ғана ірі, ал 100-і орта\n',
		},
	],
	comment_stroitelniy_10_2023: [
		{
			ru: 'Мониторинг минерально-сырьевой базы Казахстана свидетельствует о наличии достаточного количества эксплуатируемых месторождений основных видов сырья для производства строительных материалов, изделий и конструкций, в том числе и для продукции высокой степени переработки, соответствующих современным требованиям строительного комплекса.\n',
			kz: 'Қазақстанның минералдық-шикізаттық базасына жүргізілген мониторинг құрылыс материалдарын, бұйымдарын және конструкцияларын, оның ішінде қазіргі заманғы талаптарға жауап беретін жоғары өңдеу дәрежесіндегі өнімдерді өндіру үшін шикізаттың негізгі түрлерінің жеткілікті мөлшерде пайдаланылған кен орындарының бар екендігін көрсетеді. құрылыс кешені.\n',
		},
		{
			ru: 'Отечественная промышленность строительных материалов способна удовлетворять лишь часть потребностей строительного комплекса Казахстана, и как следствие существенную долю на рынке занимает импортная продукция, не допуская дефицита практически по всем видам строительных материалов. Несмотря на то, что в последние годы в Казахстане появились производства по выпуску керамической плитки, пластмассовых труб, металлочерепицы, столярных изделий из пластика, сухих смесей и другой продукции, практически вся продукция отрасли стройматериалов Казахстана неконкурентоспособна на внешнем рынке (за исключением асбеста).\n',
			kz: 'Отандық құрылыс материалдарының өнеркәсібі Қазақстанның құрылыс кешенінің қажеттіліктерінің бір бөлігін ғана қанағаттандыра алады, нәтижесінде импорттық өнімдер құрылыс материалдарының барлық түрлерінің дерлік тапшылығын болдырмай, нарықта айтарлықтай үлесті алады. Соңғы жылдары Қазақстанда керамикалық плиткалар, пластикалық құбырлар, металл плиткалар, пластмассадан жасалған ағаш бұйымдары, құрғақ қоспалар және басқа да өнімдер шығаратын өндіріс орындары пайда болғанына қарамастан, Қазақстанның құрылыс материалдары өнеркәсібінің барлық дерлік өнімдері өнеркәсіпте бәсекеге қабілетсіз. сыртқы нарық (асбесттен басқа).\n',
		},
	],
	comment_stroitelniy_11_2023: [
		{
			ru: 'Экономически выгодным и вполне реальным для Казахстана в перспективе станет экспорт новых строительных технологий в другие страны со всей сопутствующей документацией комплектной поставкой оборудования, а также с обучением местных кадров.\n',
			kz: 'Болашақта Қазақстан үшін жаңа құрылыс технологияларын барлық ілеспе құжаттамаларымен бірге басқа елдерге экспорттау, құрал-жабдықтарды толық жеткізу, сондай-ақ жергілікті кадрларды оқыту экономикалық тұрғыдан тиімді және әбден шынайы болады.\n',
		},
		{
			ru:
				'Новые технологии и материалы широко применяются в отделочных и инженерных работах, связанных с монтажом и ремонтом электрооборудования, водопроводов, отопительных систем, канализации, наружных сетей.\n' +
				'С каждым годом отмечается рост потребности в таких строительных материалах, как цемент, стекло, битум, деревянные изделия, стеновые и нерудные материалы, металлоконструкции и металлоизделия, теплоизоляционные и лакокрасочные материалы, сухие строительные смеси, природный камень, керамическая плитка, кровельные материалы, напольные покрытия.\n',
			kz:
				'Жаңа технологиялар мен материалдар электр жабдықтарын, су құбырларын, жылу жүйелерін, канализацияны, сыртқы желілерді монтаждау және жөндеуге байланысты әрлеу және инженерлік құрылыс жұмыстарында кеңінен қолданылады.\n' +
				'\n' +
				'Жыл сайын цемент, шыны, битум, ағаштан жасалған бұйымдар, қабырғалық және металл емес материалдар, металл конструкциялары мен металл бұйымдары, жылу оқшаулағыш және бояу материалдары, құрғақ құрылыс қоспалары, табиғи тас, керамикалық плиткалар, шатыр материалдары, еден жабындары. \n',
		},
	],
	comment_stroitelniy_12_2023: [
		{
			ru: 'Казахстан располагает богатыми сырьевыми возможностями для развития асбестоперерабатывающей, керамической, фарфорофаянсовой, керамзитовой, цементной, стекольной промышленности, а также развития производства извести, красок,  теплоизоляционных материалов и т.д\n',
			kz: 'Қазақстанда асбест өңдеу, керамика, фарфор-фаянс, керамзит, цемент, шыны өнеркәсібін дамытуға, сондай-ақ әк, бояу, жылу оқшаулағыш материалдар және т.б. өндірісін дамыту үшін бай шикізат бар.\n',
		},
		{
			ru:
				'Важной составляющей современной экономики является строительная промышленность. Строительные материалы являются основной базой для строительства и существенно влияют на социальное состояние общества.\n' +
				'Темпы строительства ежегодно растут. Объём строительства Казахстана в 2020 году составил 4,9 трлн тг. (рост 11%). Для поддержания данного темпа развития строительства, строительные компании Казахстана нуждаются в качественных строительных материалах.\n' +
				'Строительная промышленность привлекает как местных, так и иностранных инвесторов. Так, в 2020 году в строительство было привлечено $515,4 млн. прямых иностранных инвестиций.\n' +
				'Государством на республиканском и местном исполнительном уровне создаются условия для развития данной отрасли путём создания благоприятных условий для ведения бизнеса в стране. Инвесторам предлагаются льготные условия кредитования, таможенные пошлины, налоговые послабления и натурные гранты.\n',
			kz:
				'Қазіргі экономиканың маңызды құрамдас бөлігі құрылыс индустриясы болып табылады. Құрылыс материалдары құрылыстың негізгі базасы болып табылады және қоғамның әлеуметтік жағдайына айтарлықтай әсер етеді.\n' +
				'\n' +
				'Мемлекет республикалық және жергілікті атқарушы билік деңгейінде елімізде кәсіпкерлікті жүргізуге қолайлы жағдай жасау арқылы осы саланың дамуына жағдай жасайды. Инвесторларға несиелеудің жеңілдетілген шарттары, кедендік баждар, салық жеңілдіктері мен заттай гранттар ұсынылады.\n',
		},
	],

	comment_telemed_10_2022: [
		{
			ru: 'В рейтинге стран 2022г. по качеству системы здравоохранения Казахстан занял 58-е место из 95. Индекс страны составил 59,99. Чем выше индекс, тем система здравоохранения лучше. Индекс качества системы здравоохранения оценивает медицинских работников, оборудование, персонал, стоимость услуг и т. д. Среди стран СНГ, участвующих в рейтинге, система здравоохранения Казахстана оказалась лучшей. Хуже, чем у РК, показатели России, Украины, Беларуси и Азербайджана.\n',
			kz: 'Денсаулық сақтау жүйесінің сапасы бойынша Қазақстан 2022 жылғы елдер рейтингінде 95-тен 58-ші орынды иеленді. Ел индексі 59,99 құрады. Индекс неғұрлым жоғары болса, денсаулық сақтау жүйесі соғұрлым жақсы болады. Денсаулық сақтау жүйесінің сапа индексі медицина қызметкерлерін, жабдықтарды, персоналды, қызметтердің құнын және т.б. бағалайды. Ресей, Украина, Беларусь және Әзірбайжанның көрсеткіштері ҚР-дан нашар.\n',
		},
		{
			ru: 'Естественный прирост населения Казахстана за первое полугодие 2022 года составил 128,9 тыс. человек, для сравнения – в аналогичный период годом ранее эта цифра составляла 139,7 тыс. человек. То есть естественный прирост населения Казахстана снизился на 7,7%. Общий коэффициент естественного прироста на 1000 населения составил 13,51 человека.\n',
			kz: '2022 жылдың бірінші жартыжылдығында Қазақстан халқының табиғи өсімі 128,9 мың адамды құрады, салыстыру үшін-осыған ұқсас кезеңде бір жыл бұрын бұл көрсеткіш 139,7 мың адамды құрады. Яғни, Қазақстан халқының табиғи өсімі 7,7% - ға төмендеді. 1000 тұрғынға шаққандағы табиғи өсімнің жалпы коэффициенті 13,51 адамды құрады.\n',
		},
	],
	comment_telemed_11_2022: [
		{
			ru: 'Министерство здравоохранения Казахстана предлагает запретить в стране оборот вейпов. Информация об этом размещена в проекте закона «О внесении изменений и дополнений в некоторые законодательные акты РК по вопросам здравоохранения» в числе ряда изменений в области охраны здоровья граждан республики. Документ опубликован на портале «Открытые НПА» и находится на публичном обсуждении до 8 ноября.\n',
			kz: 'Қазақстанның денсаулық сақтау министрлігі елде вейп айналымына тыйым салуды ұсынады. Бұл туралы ақпарат "ҚР кейбір заңнамалық актілеріне денсаулық сақтау мәселелері бойынша өзгерістер мен толықтырулар енгізу туралы" заң жобасында республика азаматтарының денсаулығын сақтау саласындағы бірқатар өзгерістердің қатарында орналастырылған. Құжат "Ашық НҚА" порталында жарияланды және 8 қарашаға дейін жария талқылауда.\n',
		},
		{
			ru:
				'В Казахстане началась массовая вакцинация населения против гриппа. К сезонной вспышке острых респираторных вирусных инфекций готовятся и в Шымкенте. Сюда уже доставили почти 135 тысяч доз вакцины. Бесплатную прививку получат дети, в возрасте от 6 месяцев до года, воспитанники детских домов, пожилые люди, работники сферы здравоохранения и пациенты, состоящие на диспансерном учёте. К слову, в мегаполисе с начала года ОРВИ переболели более 22 тысяч человек. Показатели выше прошлогодних, - почти в два раза. Чаще всего вирусную инфекцию диагностировали у детей до 14 лет.\n' +
				'\n' +
				'Источник: https://24.kz/ru/news/social/item/564662-v-kazakhstane-nachalas-massovaya-vaktsinatsiya-naseleniya-protiv-grippa\n',
			kz:
				'Қазақстанда халықты тұмауға қарсы жаппай вакцинациялау басталды. Шымкентте жіті респираторлық вирустық инфекциялардың маусымдық өршуіне дайындалуда. Мұнда вакцинаның 135 мыңға жуық дозасы жеткізілді. 6 айдан бір жасқа дейінгі балалар, балалар үйінің тәрбиеленушілері, қарт адамдар, денсаулық сақтау саласының қызметкерлері және диспансерлік есепте тұрған пациенттер тегін екпе алады. Айта кетейік, мегаполисте жыл басынан бері 22 мыңнан астам адам ЖРВИ-мен ауырған. Көрсеткіштер былтырғыдан екі есе жоғары. Көбінесе вирустық инфекция 14 жасқа дейінгі балаларда диагноз қойылды.\n' +
				'\n' +
				'Дереккөз: https://24.kz/ru/news/social/item/564662-v-kazakhstane-nachalas-massovaya-vaktsinatsiya-naseleniya-protiv-grippa\n',
		},
	],
	comment_telemed_12_2022: [
		{
			ru:
				'Телемедицина в Казахстане: какие направления охватывает дистанционная мед помощь.\n' +
				'Телемедицина — это дистанционное предоставление медицинских услуг (например, мониторинг состояния пациента и консультации) и взаимодействие медицинских работников между собой с помощью телекоммуникационных технологий.\n',
			kz:
				'Қазақстандағы Телемедицина: қашықтықтан медициналық көмек қандай бағыттарды қамтиды.\n' +
				'Телемедицина-бұл қашықтықтан медициналық қызмет көрсету (мысалы, пациенттің жағдайын бақылау және кеңес беру) және телекоммуникация технологиялары арқылы медицина қызметкерлерінің өзара әрекеттесуі.\n',
		},
		{
			ru:
				'Естественно, в первую очередь данная технология призвана облегчить доступ жителям отдаленных районов. Какие же основные направления покрывает телемедицина?\n' +
				'·        Оказание консультативной помощи, в том числе специалистами научно-исследовательских институтов, научных центров, университетских больниц, а также зарубежными клиниками.\n',
			kz:
				'Әрине, ең алдымен, бұл технология шалғай аудандардың тұрғындарына қол жетімділікті жеңілдетуге арналған. Телемедицина қандай негізгі бағыттарды қамтиды?\n' +
				'\n' +
				'* Ғылыми-зерттеу институттарының, ғылыми орталықтардың, университеттік ауруханалардың, сондай-ақ шетелдік клиникалардың мамандарымен консультациялық көмек көрсету.\n',
		},
	],
	comment_telemed_1_2023: [
		{
			ru:
				'Определение целесообразности направления на очную консультацию или транспортировку пациента в вышестоящие уровни оказания медицинской помощи, например, в клиники республиканского уровня.\n' +
				'·        Оказание практической помощи узкими специалистами медработникам первичного и сельского уровня – в части уточнения диагноза, оценки эффективности лечения, корректировки тактики ведения пациента, назначений лечебно-диагностических мероприятий и т.д.\n' +
				'·        Организация дистанционных консилиумов.',
			kz:
				'* Күндізгі консультацияға немесе пациентті медициналық көмек көрсетудің жоғары деңгейлеріне, мысалы, республикалық деңгейдегі клиникаларға тасымалдауға жіберудің орындылығын анықтау.\n' +
				'\n' +
				'* Тар мамандардың бастапқы және ауылдық деңгейдегі медицина қызметкерлеріне практикалық көмек көрсетуі-диагнозды нақтылау, емдеу тиімділігін бағалау, пациентті жүргізу тактикасын түзету, емдеу-диагностикалық іс-шараларды тағайындау және т. б.\n' +
				'\n' +
				'* Қашықтықтан консилиумдар ұйымдастыру.\n',
		},
		{
			ru:
				'В гарантированный объем бесплатной медицинской помощи в Республике Казахстан входят:\n' +
				'cкорая медицинская помощь, включая медицинскую авиацию;\n' +
				'первичная медико-санитарная помощь, включая диагностику, лечение, профилактические осмотры, санитарно-противоэпидемические и санитарно-профилактические мероприятия в очагах инфекционных заболеваний;\n' +
				'специализированная медицинская помощь в амбулаторных условиях, включая профилактику и диагностику вич-инфекции и туберкулеза, услуги при неотложных состояниях, диагностика и лечение при социально-значимых и хронических заболеваний;\n',
			kz:
				'. Қазақстан Республикасында тегін медициналық көмектің кепілдік берілген көлеміне мыналар кіреді:\n' +
				'* медициналық авиацияны қоса алғанда, Жедел медициналық көмек;\n' +
				'* инфекциялық аурулар ошақтарындағы диагностиканы, емдеуді, профилактикалық тексерулерді, санитариялық-эпидемияға қарсы және санитариялық-профилактикалық іс-шараларды қоса алғанда, алғашқы медициналық-санитариялық көмек;\n',
		},
	],
	comment_telemed_2_2023: [
		{
			ru:
				'пециализированная медицинская помощь в стационарозамещающих условиях, включая лечение при социально-значимых и хронических заболеваниях; услуги стационара на дому;\n' +
				'специализированная медицинская помощь в стационарных условиях, включая лечение инфекционных, паразитарных заболеваний, представляющих опасность для окружающих и подозрение на них;\n' +
				'обеспечение лекарственными средствами, включая медицинские изделия, иммунобиологические лекарственные препараты при оказании скорой и специализированной помощи ПМСП в соответствии с перечнем заболеваний, против которых проводятся профилактические прививки и специализированной медицинской помощи в амбулаторных условиях.\n',
			kz:
				'* АИТВ-инфекциясы мен туберкулездің профилактикасы мен диагностикасын қоса алғанда, амбулаториялық жағдайларда мамандандырылған медициналық көмек, шұғыл жағдайлар кезіндегі қызметтер, әлеуметтік маңызы бар және созылмалы аурулар кезінде диагностика және емдеу;\n' +
				'* әлеуметтік маңызы бар және созылмалы аурулар кезінде емдеуді қоса алғанда, стационарды алмастыратын жағдайларда мамандандырылған медициналық көмек; үйдегі стационар қызметтері;\n' +
				'* айналасындағыларға қауіп төндіретін және оларға күдік тудыратын инфекциялық, паразиттік ауруларды емдеуді қоса алғанда, стационарлық жағдайларда мамандандырылған медициналық көмек;\n' +
				'* амбулаториялық жағдайларда профилактикалық егулер жүргізілетін аурулар тізбесіне сәйкес МСАК жедел және мамандандырылған көмек көрсету кезінде медициналық бұйымдарды, иммунобиологиялық дәрілік препараттарды қоса алғанда, дәрілік заттармен қамтамасыз ету.\n',
		},
		{
			ru:
				'В ОСМС (Обязательное социальное медицинское страхование) в РК входят следующие медицинские услуги:\n' +
				'профилактические медицинские осмотры;\n' +
				'прием и консультации врачей узкого профиля по направлению участкового врача;\n' +
				'динамическое наблюдение профильными специалистами лиц с хроническими заболеваниями;\n',
			kz:
				'ҚР-да ММС (Міндетті әлеуметтік медициналық сақтандыру) мынадай медициналық қызметтерді қамтиды:\n' +
				'* профилактикалық медициналық тексерулер;\n' +
				'* учаскелік дәрігердің жолдамасы бойынша тар бейінді дәрігерлерді қабылдау және консультациялар;\n' +
				'* созылмалы аурулары бар адамдарды бейінді мамандардың динамикалық бақылауы;\n',
		},
	],
	comment_telemed_3_2023: [
		{
			ru:
				'оказание стоматологической помощи в экстренной и плановой форме отдельным категориям населения;\n' +
				'диагностические услуги, в том числе лабораторная диагностика (УЗИ, рентген, КТ, МРТ);\n' +
				'специализированная, в том числе высокотехнологичная, стационарная медицинская помощь;\n' +
				'медицинская реабилитация;\n' +
				'обеспечение лекарственными средствами, при оказании специализированной медицинской помощи в стационарных условиях.\n',
			kz:
				'* халықтың жекелеген санаттарына шұғыл және жоспарлы түрде стоматологиялық көмек көрсету;\n' +
				'* диагностикалық қызметтер, оның ішінде зертханалық диагностика (УДЗ, рентген, КТ, МРТ);\n' +
				'* мамандандырылған, оның ішінде жоғары технологиялық, стационарлық медициналық көмек;\n' +
				'* медициналық оңалту;\n' +
				'* стационарлық жағдайларда мамандандырылған медициналық көмек көрсету кезінде дәрілік заттармен қамтамасыз ету.\n',
		},
		{
			ru: 'В Алматы за прошедшую неделю зарегистрировано 23 995 случаев ОРВИ, что ниже показателя аналогичного периода прошлого года на 28,3%\n',
			kz: 'Алматыда соңғы аптада 23 995 жедел респираторлық вирустық инфекция тіркелді, бұл өткен жылдың сәйкес кезеңімен салыстырғанда 28,3%-ға төмен.\n',
		},
	],
	comment_telemed_4_2023: [
		{
			ru: 'Инвестиции в сферу здравоохранения и социальных услуг выросли в стоимостном выражении на 18% за год. Почти половина капвложений пришлась на три региона — Астану, а также Жамбылскую и Восточно-Казахстанскую области\n',
			kz: 'Денсаулық сақтау және әлеуметтік қызметтерге инвестиция құндық мәнде бір жылмен салыстырғанда 18%-ға өсті. Күрделі салымдардың жартысына жуығы үш облысқа – Астанаға, сондай-ақ Жамбыл және Шығыс Қазақстан облыстарына құйылды.\n',
		},
		{
			ru: 'Специалисты Национального научного центра травматологии и ортопедии имени академика Н.Д. Батпенова начали проводить в Казахстане видеоконсультации пациентов по вопросам первичного эндопротезирования крупных суставов и других опорно-двигательных заболеваний\n',
			kz: 'Академик Н.Д. атындағы травматология және ортопедия ұлттық ғылыми орталығының мамандары. Батпенова ірі буындар мен басқа да тірек-қимыл аппараты ауруларының бастапқы артропластикасы мәселелері бойынша Қазақстандағы науқастарға бейнеконсультациялар жүргізе бастады.\n',
		},
	],
	comment_telemed_5_2023: [
		{
			ru: 'За 9 месяцев текушего года в республике успешно выполнено свыше 113 тысяч уникальных хирургических вмешательств.\n',
			kz: 'Ағымдағы жылдың 9 айында республикада 113 мыңнан астам бірегей хирургиялық араласу сәтті орындалды.\n',
		},
		{
			ru: 'Врачебная амбулатория на 50 посещений и Центр семейного здоровья на 200 посещений введены в эксплуатацию в этом году в Целиноградском районе Акмолинской области\n',
			kz: 'Биыл Ақмола облысының Целиноград ауданында 50 келушіге арналған дәрігерлік амбулатория мен 200 келушіге арналған отбасылық денсаулық орталығы пайдалануға берілді.\n',
		},
	],
	comment_telemed_6_2023: [
		{
			ru: 'По итогам 9 месяцев 2022 года, отмечается снижение показателя смертности на 100 тысяч населения от болезней системы кровообращения почти на 32%, который составил 154,4 против 228,4 за аналогичный период 2021 года, от злокачественных новообразований – на 9% с 74,7 до 67,9, травм – на 4% с 61,1 в 2021 году до 57,9 в 2022 году, от болезней органов дыхания на 33% с 106,6 до 66,6 в 2022 году, от туберкулеза на 17%, с 1,6 в 2021 году до 1,4 в 2022 году.\n',
			kz: '2022 жылдың 9 айының қорытындысы бойынша қан айналымы жүйесі ауруларынан 100 мың халыққа шаққандағы өлім-жітім көрсеткішінің 32%-ға дерлік төмендегені байқалады, бұл 2021 жылғы сәйкес кезеңдегі 228,4-ке қарсы 154,4-ті құрады, қатерлі аурулардан. ісіктер – 9%-ға 74,7-ден 67,9-ға дейін, жарақаттар – 4%-ға 2021 ж. 61,1-ден 2022 ж. 57,9-ға дейін, респираторлық аурулардан 33%-ға 106,6-дан 66,6-ға дейін, 2022 ж. 2022.\n',
		},
		{
			ru: 'Отмечается значительное сокращение материнской смертности – более, чем в 3,5 раза. За 10 месяцев 2022 года показатель материнской смертности составил 14,3 против 46,2 на 100 тысяч родившихся живыми.\n',
			kz: 'Ана өлімі айтарлықтай төмендеді – 3,5 еседен астам», - деді министр. 2022 жылдың 10 айында ана өлімі 100 000 тірі туылғанға шаққанда 46,2-ден 14,3-ті құрады.\n',
		},
	],
	comment_telemed_7_2023: [
		{
			ru: 'Кадровый потенциал организаций ПМСП составляет около 90 тысяч медицинских работников. В настоящее время нагрузка на одного врача общей практики составляет в среднем 1 830 человек. Наряду с этим, для повышения эффективности медицинской помощи, создается участковая мультидисциплинарная бригада ПМСП, включающая помимо участкового врача, трех средних медицинских работников, социального работника, медицинского психолога, а также при необходимости других профильных специалистов\n',
			kz: 'МСАК ұйымдарының кадрлық әлеуеті 90 мыңға жуық медицина қызметкерін құрайды. Қазіргі уақытта жалпы тәжірибелік дәрігерге шаққандағы жүктеме орташа есеппен 1830-ды құрайды. Осымен қатар медициналық көмектің тиімділігін арттыру мақсатында аудандық көпсалалы МСАК командасы құрылуда, оның құрамына учаскелік дәрігерден басқа үш фельдшер, әлеуметтік қызметкер, медициналық психолог, қажет болған жағдайда басқа мамандандырылған мамандар\n',
		},
		{
			ru:
				'В Республике Казахстан ведущие позиции на рынке фармакологии занимают следующие компании:\n' +
				'Santo;\n' +
				'Sanofi-Aventis;\n' +
				'Sandoz;\n' +
				'Bayer;\n' +
				'GlaxoSmithKLine.\n',
			kz:
				'Қазақстан Республикасында фармакология нарығында жетекші орындарды келесі компаниялар иеленеді:\n' +
				'\n' +
				'Санто;\n' +
				'Sanofi-Aventis;\n' +
				'Сандоз;\n' +
				'Байер;\n' +
				'GlaxoSmithKline.\n',
		},
	],
	comment_telemed_8_2023: [
		{
			ru: 'Инновационные противоопухолевые препараты начнут производить в Казахстане\n',
			kz: 'Қазақстанда ісікке қарсы инновациялық препараттар шығарылатын болады\n',
		},
		{
			ru: 'В Алматы впервые провели операцию по пересадке искусственного сердца. Осуществили её специалисты НИИ кардиологии и внутренних болезней\n',
			kz: 'Алматыда алғаш рет жасанды жүректі ауыстыру операциясы жасалды. Оны кардиология және ішкі аурулар ғылыми-зерттеу институтының мамандары жүргізді\n',
		},
	],
	comment_telemed_9_2023: [
		{
			ru: 'В Астане впервые в СНГ проведена операция по имплатации дефибриллятора с функцией передачи данных на мобильный телефон\n',
			kz: 'Астанада ТМД елдерінде алғаш рет деректерді ұялы телефонға тасымалдау функциясы бар дефибрилляторды имплантациялау операциясы жасалды.\n',
		},
		{
			ru: 'Врачам удалось вернуть зрение слепой пенсионерке в Алматы В Алматы провели сложную операцию 68-летней женщине с диагнозом "Глаукома левого глаза с осложненной катарактой"\n',
			kz:
				'Алматыда дәрігерлер зағип зейнеткердің көзін қалпына келтірді\n' +
				'Алматыда сол жақ көзінің глаукомасының асқынған катарактасымен ауыратын 68 жастағы әйелге күрделі ота жасалды.\n',
		},
	],
	comment_telemed_10_2023: [
		{
			ru: 'Страхование профессиональной ответственности медработников введут в Казахстане  Казахстанцам будут возмещать вред здоровью, причинённый при оказании медпомощи. Такую норму предусматривают законодательные нововведения, связанные с внедрением страхования профессиональной ответственности медицинских работников. \n',
			kz:
				'Қазақстанда медицина қызметкерлерінің кәсіби жауапкершілігін сақтандыру енгізіледі\n' +
				'Қазақстандықтарға медициналық көмек көрсету кезінде денсаулығына келтірілген зиян өтеледі. Мұндай норма медицина қызметкерлерінің кәсіби жауапкершілігін сақтандыруды енгізуге байланысты заңнамалық жаңалықтарда қарастырылған.\n',
		},
		{
			ru: 'Розовый октябрь, скрининги для женщин и специальный отпуск. Что нужно знать о раке молочной железы? В 1993 году ВОЗ учредила Всемирный День борьбы против рака молочной железы. Его отмечают 15 октября. Ещё одна дата — 21 октября — Всемирный день осведомлённости о раке молочной железы. Мероприятия по этому поводу проходят в более чем 44 странах мира, потому что рак — проблема, которая объединяет все национальности.\n',
			kz:
				'Қызғылт қазан, әйелдер көрсетілімі және ерекше мереке. Сүт безі қатерлі ісігі туралы не білуіңіз керек\n' +
				'1993 жылы ДДҰ Дүниежүзілік сүт безі обырына қарсы күрес күнін белгіледі. 15 қазанда тойланады. Тағы бір күн – 21 қазан – Сүт безі қатерлі ісігі туралы дүниежүзілік ақпараттандыру күні. Осыған орай іс-шаралар әлемнің 44-тен астам елінде өткізілуде, өйткені қатерлі ісік барлық ұлттарды біріктіретін мәселе.\n',
		},
	],
	comment_telemed_11_2023: [
		{
			ru: 'Впервые в Алматы местные хирурги провели малоинвазивную операцию на позвоночнике. В городе на базе клинической больницы №7 проходит республиканская научно-практическая конференция "Актуальные вопросы нейрохирургии". Мастер-класс по спинальной нейрохирургии для казахстанских врачей провёл завкафедрой нейрохирургии и инновационной медицины Иркутского государственного медуниверситета Вадим Бывальцев.\n',
			kz: 'Алматыда алғаш рет жергілікті хирургтар омыртқаға шағын инвазивті ота жасады. Қалада No7 клиникалық аурухана базасында «Нейрохирургияның өзекті мәселелері» атты республикалық ғылыми-тәжірибелік конференция өтуде. Қазақстандық дәрігерлерге жұлын нейрохирургиясы бойынша шеберлік сабағын Иркутск мемлекеттік медицина университетінің нейрохирургия және инновациялық медицина кафедрасының меңгерушісі Вадим Бывальцев өткізді.\n',
		},
		{
			ru: 'Вакцинацию против вируса папилломы человека (ВПЧ) хотят сделать обязательной в Казахстане. Об этом сообщили в Комитете санитарно-эпидемиологического контроля Минздрава РК. \n',
			kz: 'Қазақстанда адам папилломавирусына (HPV) қарсы вакцинация міндетті түрде енгізілмек. Бұл туралы ҚР Денсаулық сақтау министрлігінің Санитарлық-эпидемиологиялық бақылау комитетінен мәлім етті, деп хабарлайды ҚазАқпарат.\n',
		},
	],
	comment_telemed_12_2023: [
		{
			ru: 'Советник по вопросам защиты прав пациентов появился у главы Минздрава РК\n',
			kz: 'ҚР Денсаулық сақтау министрлігіне пациенттердің құқықтарын қорғау жөніндегі кеңесші келді\n',
		},
		{
			ru:
				'Протез уха годовалому ребёнку учили устанавливать столичных врачей\n' +
				'В многопрофильной городской детской больнице №2 состоялся мастер-класс по обмену опытом  среди ЛОР-врачей\n',
			kz:
				'Елордалық дәрігерлерге бір жасар балаға құлақ протезін орнатуды үйретті\n' +
				'No2 көпсалалы қалалық балалар ауруханасында ЛОР дәрігерлері тәжірибе алмасу бойынша шеберлік сыныбы өтті.\n',
		},
	],
}
