export const left_bar_dictionary = {
	ru: {
		0: 'Сводные данные',
		1: 'Упоминания',
		2: 'Тональность',
		3: 'Теги',
		4: 'Источники',
		5: 'Авторы',
		// 6: 'Сообщества',
		7: 'География',
		// 8: 'Популярные слова',
		// 9: 'Ссылки',
		// 10: 'Персоны',
		// 11: 'Юрлица',
		12: 'Места',
	},
	kz: {
		0: 'Жиынтық деректер',
		1: 'Ескерту',
		2: 'Кілт',
		3: 'Тегтер',
		4: 'Деректер',
		5: 'Авторлар',
		// 6: 'Қауымдастықтар',
		7: 'География',
		// 8: 'Танымал сөздер',
		// 9: 'Сілтемелер',
		// 10: 'Тұлғалар',
		// 11: 'Занды тұлға',
		12: 'Жерлер',
	},
}

export const tabs_fields_dictionary = {
	0: 'summary',
	1: 'raw_data',
	2: ['tonality', 'tonality_total_day'],
	3: ['tags_top_10', 'tags_total_day'],
	4: ['hub_top_10', 'hub_total'],
	5: ['authors', 'authors_by_age_stat'],
	7: ['countries', 'cities', 'regions'],
	12: 'places',
}
